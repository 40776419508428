import React, { FC, ReactNode } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { isFontAwesomeIconProps } from "../PropTypes";

interface Props {
    prependIcon?: IconProp | FontAwesomeIconProps;
    prependText?: string;
    appendIcon?: IconProp | FontAwesomeIconProps;
    appendText?: string;
}

const IconFieldGroup: FC<Props> = ({
    children,
    prependIcon,
    prependText,
    appendIcon,
    appendText,
    ...restProps
}) => {

    const renderIconGroupContent = 
        (prependIcon: IconProp | FontAwesomeIconProps | undefined, prependText: string | undefined): ReactNode => {
            if (!prependIcon && !prependText) return null;
            
            if (prependIcon) {
                const iconProps: FontAwesomeIconProps =
                    isFontAwesomeIconProps(prependIcon)
                    ? prependIcon
                    : { icon: prependIcon, size: "sm" };
                return <FontAwesomeIcon fixedWidth {...iconProps} />;
            } else {
                return <span><strong>{prependText}</strong></span>;
            }
        };

    let prependIconTemplate;

    if (prependIcon || prependText) {
        prependIconTemplate = (
            <div className="input-group-prepend">
                <span className="input-group-text">
                    { renderIconGroupContent(prependIcon, prependText) }
                </span>
            </div>
        );
    }

    let appendIconTemplate;

    if (appendIcon || appendText) {
        appendIconTemplate = (
            <div className="input-group-append">
                <span className="input-group-text">
                    { renderIconGroupContent(appendIcon, appendText) }
                </span>
            </div>
        );
    }

    return (
        <div className="input-group" {...restProps}>
            {prependIconTemplate}
            {children}
            {appendIconTemplate}
        </div>
    );
};

export default IconFieldGroup;
