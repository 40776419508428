import React, { FC, useMemo } from "react";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../../components/templates/ContentHeader";
import ContentBody from "../../components/templates/ContentBody";

import LinkButton from "../../components/buttons/LinkButton";
import ContextDashboard from "../../templates/dashboard/ContextDashboard";
import { useGetTenantsQuery } from "../../services/Tenants";
import { Tenant } from "../../services/Models";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import TenantListingCard from "./TenantListingCard";
import { maxPageSize } from "../../const";

const TenantListing: FC = () => {

    const { isLoading, isFetching, isSuccess, isError,
            data, error } = useGetTenantsQuery({ size: maxPageSize });

    const tenants = useMemo(() => data?.items || [], [ data ]);

    const renderTenantCard = (tenant: Tenant) => {
        return (
            <TenantListingCard key={tenant.tenantId} tenant={tenant} />
        );
    };

    const renderTenants = (tenants: Tenant[]) => {
        return (
            <Row>
                <Col sm="12">
                    { tenants.map(renderTenantCard) }
                </Col>
            </Row>
        );
    };

    return (
        <>
            <ContextDashboard>
                <ContentHeader>
                    <Row>
                        <Col sm="6">
                            <h1 className="float-left mr-5">Tenants</h1>
                            <LinkButton
                                variant="outline-primary"
                                icon="plus"
                                to="0/edit"
                            >
                                Add Tenant
                            </LinkButton>
                        </Col>
                        <Col sm="6">
                        </Col>
                    </Row>
                </ContentHeader>
                <ContentBody>
                    <SpinnerOverlay
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        error={error}
                    >
                        { renderTenants(tenants) }
                    </SpinnerOverlay>
                </ContentBody>
            </ContextDashboard>
        </>
    );
};

export default TenantListing;