import React, { ChangeEvent, ChangeEventHandler, FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import * as Yup from "yup";
import { Form, Formik } from "formik";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../../components/templates/ContentHeader";
import ContentBody from "../../components/templates/ContentBody";
import Card from "../../components/templates/Card";
import CardHeader from "../../components/templates/CardHeader";
import CardBody from "../../components/templates/CardBody";
import LabelFieldGroup from "../../components/fields/LabelFieldGroup";
import IconFieldGroup from "../../components/fields/IconFieldGroup";
import AppButton from "../../components/buttons/AppButton";
import CardFooter from "../../components/templates/CardFooter";
import InputField from "../../components/fields/InputField";
import ValidationFieldGroup from "../../components/fields/ValidationFieldGroup";
import LinkButton from "../../components/buttons/LinkButton";
import { useGetTenantQuery, useSaveTenantMutation } from "../../services/Tenants";
import { Tenant } from "../../services/Models";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import { setFormValueCallback } from "../../components/PropTypes";
import ContextDashboard from "../../templates/dashboard/ContextDashboard";

const TenantForm: FC = () => {

    let { tenantId } = useParams();
    tenantId = tenantId ? tenantId : "0";

    const navigate = useNavigate();

    const [ formValues, setFormValues ] =
        useState({
            id: "",
            tenantId: "",
            name: "",
        });

     const validationSchema =
         Yup.object().shape({
             tenantId: Yup.string().required("Required"),
             name: Yup.string().required("Required"),
         });

    const {
        isLoading: isLoading,
        isFetching: isFetching,
        isSuccess: isSuccessGet,
        isError: isErrorGet,
        data: tenant,
        error: errorGet,
    } = useGetTenantQuery({ tenantId });

    const [
        saveTenant,
        {
            isLoading: isUpdating,
            isSuccess: isSuccessSave,
            isError: isErrorSave,
            error: errorSave,
        },
    ] = useSaveTenantMutation();

    const onSubmit =
        (values: Tenant) => {
            saveTenant({
                tenantId: (tenantId ? tenantId : values.tenantId), 
                tenant: values, 
            });
        };
    
    useEffect(() => {
        if (isSuccessSave) navigate("..", { replace: true });
    }, [ isSuccessSave, navigate ]);

    const setFormValue = (e: ChangeEvent<HTMLInputElement>, field: string, value: unknown, handleChange: ChangeEventHandler, setFieldValue: setFormValueCallback) => {
        handleChange(e);
        setFieldValue(e.target.name, e.target.value);
        setFieldValue(field, value);
    };

    useEffect(() => {
        if (!tenantId || tenantId === "0") return;

        if (isSuccessGet && tenant) {
            const retrievedFormValues = _.cloneDeep(tenant);
            setFormValues({ ...retrievedFormValues });
        }
    }, [ isSuccessGet, tenant, tenantId ]);

    return (
        <>
            <ContextDashboard>
                <ContentHeader>
                    <Row>
                        <Col sm="6">
                            <h1 className="float-left mr-5">Tenant</h1>
                            <LinkButton
                                variant="outline-primary"
                                icon="chevron-up"
                                to=".."
                            >
                                Tenants
                            </LinkButton>
                        </Col>
                        <Col sm="6">
                        </Col>
                    </Row>
                </ContentHeader>
                <ContentBody>
                    <SpinnerOverlay
                        isLoading={isLoading || isUpdating}
                        isFetching={isFetching || isUpdating}
                        isSuccess={isSuccessGet || isSuccessSave}
                        isError={((tenantId && tenantId !== "0") && isErrorGet) || isErrorSave}
                        error={((tenantId && tenantId !== "0") && errorGet) || errorSave}
                    >
                    <Formik initialValues={formValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                    >
                        {({ handleChange, setFieldValue, errors, touched }) => (
                        <Form className="form-horizontal">
                            <Row>
                                <Col md="6">
                                    <Card>
                                        <CardHeader title="Identification" prependIcon="fingerprint" />
                                        <CardBody>
                                            <LabelFieldGroup label="Identifier">
                                                <ValidationFieldGroup name="tenantId">
                                                    <InputField name="id" type="hidden" errors={errors} touched={touched} />
                                                    <IconFieldGroup prependIcon="fingerprint">
                                                        <InputField
                                                            name="tenantId" errors={errors} touched={touched}
                                                            readOnly={(tenantId && tenantId !== "0") as boolean}
                                                            onChange={
                                                                (e: ChangeEvent<HTMLInputElement>) =>
                                                                    setFormValue(e, "id", e.target.value, handleChange, setFieldValue)
                                                            }
                                                        />
                                                    </IconFieldGroup>
                                                </ValidationFieldGroup>
                                            </LabelFieldGroup>
                                            <LabelFieldGroup label="Name">
                                                <ValidationFieldGroup name="name">
                                                    <IconFieldGroup prependIcon="satellite">
                                                        <InputField name="name" errors={errors} touched={touched} onChange={handleChange} />
                                                    </IconFieldGroup>
                                                </ValidationFieldGroup>
                                            </LabelFieldGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6">
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardFooter className="text-center">
                                            <AppButton
                                                variant="primary"
                                                type="submit"
                                                className="mr-3"
                                            >
                                                Save
                                            </AppButton>
                                            <LinkButton
                                                variant="outline-primary"
                                                to=".."
                                                className="mr-3"
                                            >
                                                Cancel
                                            </LinkButton>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                        )}
                    </Formik>
                    </SpinnerOverlay>
                </ContentBody>
            </ContextDashboard>
        </>
    );
};

export default TenantForm;