import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import Header from "./header/Header";
import Branding from "./branding/Branding";
import Footer from "./footer/Footer";
import ProbeListing from "../../scenes/probes/ProbeListing";
import ProbeView from "../../scenes/probes/ProbeView";
import ProbeForm from "../../scenes/probes/ProbeForm";
import TestRunListing from "../../scenes/test_runs/TestRunListing";
import TestRunView from "../../scenes/test_runs/TestRunView";
import TenantListing from "../../scenes/tenants/TenantListing";
import TenantView from "../../scenes/tenants/TenantView";
import TenantForm from "../../scenes/tenants/TenantForm";
import ClientListing from "../../scenes/clients/ClientListing";
import ClientForm from "../../scenes/clients/ClientForm";
import ClientView from "../../scenes/clients/ClientView";
import SiteListing from "../../scenes/sites/SiteListing";
import SiteForm from "../../scenes/sites/SiteForm";
import SiteView from "../../scenes/sites/SiteView";
import ProbeStatusDashboard from "../../scenes/dashboard/ProbeStatusDashboard";

const Main: FC = () => {

    const testRunRoutes = (path: string) =>
        <Route path={path}>
            <Route path="" element={<TestRunListing/>}/>
            <Route path=":testRunStateId/report">
                <Route path="" element={<TestRunView/>}/>
            </Route>
        </Route>;

    const probeRoutes = (path: string) =>
        <Route path={path}>
            <Route path="" element={<ProbeListing/>}/>
            <Route path=":probeId/edit" element={<ProbeForm/>}/>
            <Route path=":probeId" element={<ProbeView/>}/>
            {testRunRoutes(":probeId/test-runs")}
        </Route>;

    const siteRoutes = (path: string) =>
        <Route path={path}>
            <Route path="" element={<SiteListing/>}/>
            <Route path=":siteId/edit" element={<SiteForm/>}/>
            <Route path=":siteId" element={<SiteView/>}/>
            {probeRoutes(":siteId/probes")}
        </Route>;

    const clientRoutes = (path: string) =>
        <Route path={path}>
            <Route path="" element={<ClientListing/>}/>
            <Route path=":clientId/edit" element={<ClientForm/>}/>
            <Route path=":clientId" element={<ClientView/>}/>
            {siteRoutes(":clientId/sites")}
        </Route>;

    const tenantRoutes = (path: string) =>
        <Route path={path}>
            <Route path="" element={<TenantListing/>}/>
            <Route path=":tenantId" element={<TenantView/>}/>
            <Route path=":tenantId/edit" element={<TenantForm/>}/>
            {clientRoutes(":tenantId/clients")}
        </Route>;

    return (
        <div className="hold-transition sidebar-mini layout-navbar-fixed layout-footer-fixed">
            <div className="wrapper">
                <Header/>
                <Branding/>
                <div className="content-wrapper">
                    <section className="content">
                        <Routes>
                            { tenantRoutes("/tenants") }
                            { clientRoutes("/clients") }
                            { siteRoutes("/sites") }
                            { probeRoutes("/probes") }
                            {testRunRoutes("/test-runs")}
                            <Route path="/" element={<ProbeStatusDashboard />} />
                        </Routes>
                    </section>
                </div>
                <Footer/>
            </div>
            <ToastContainer
                autoClose={5000}
            />
        </div>
    );

};

export default Main;
