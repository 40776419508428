import React, { FC } from "react";

import moment from "moment";

import { version } from "../../../../../../package.json";

const Footer: FC = () => {
    return (
        <footer className="main-footer">
            <strong>
                <span className="mr-1">
                    Copyright &#169; 
{" "}
{moment(new Date()).local().format("YYYY")}
                </span>
                <a
                    href="https://southerncross.solutions"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Southern Cross Solutions (Pty) Ltd
                </a>
                <span>.</span>
            </strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b>
                <span>
&nbsp;
{version}
                </span>
            </div>
        </footer>
    );
};

export default Footer;
