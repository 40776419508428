import { configureStore, Middleware, Store } from "@reduxjs/toolkit";
import { cpwpApi } from "./services/CpwpApi";
import { testRunListingReducer } from "./states/TestRunListing";

const loggingMiddleware: Middleware<unknown, RootState>  = ({ getState }) => next => action => {

    console.debug("Dispatch Action:", action);
    console.debug("State before Dispatch:", getState());

    const returnValue = next(action);

    console.debug("State after Dispatch:", getState());

    return returnValue;

};

const store: Store = configureStore({
    reducer: {
        [cpwpApi.reducerPath]: cpwpApi.reducer,
        testRunListing: testRunListingReducer,
    },
    middleware:
        (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(cpwpApi.middleware)
                .prepend(loggingMiddleware),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
