import React, { FC, useMemo } from "react";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../../components/templates/ContentHeader";
import ContentBody from "../../components/templates/ContentBody";

import LinkButton from "../../components/buttons/LinkButton";
import ContextDashboard from "../../templates/dashboard/ContextDashboard";
import { useGetSitesQuery } from "../../services/Sites";
import { Site } from "../../services/Models";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import SiteListingCard from "./SiteListingCard";
import { maxPageSize } from "../../const";
import { useParams } from "react-router-dom";

const SiteListing: FC = () => {

    const { clientId } = useParams();

    const { isLoading, isFetching, isSuccess, isError,
            data, error } = useGetSitesQuery({ clientId, size: maxPageSize });

    const sites = useMemo(() => data?.items || [], [ data ]);

    const renderSiteCard = (site: Site) => {
        return (
            <SiteListingCard key={site.siteId} site={site} />
        );
    };

    const renderSites = (sites: Site[]) => {
        return (
            <Row>
                <Col sm="12">
                    { sites.map(renderSiteCard) }
                </Col>
            </Row>
        );
    };

    return (
        <>
            <ContextDashboard>
                <ContentHeader>
                    <Row>
                        <Col sm="6">
                            <h1 className="float-left mr-5">Sites</h1>
                            <LinkButton
                                variant="outline-primary"
                                icon="plus"
                                to="0/edit"
                            >
                                Add Site
                            </LinkButton>
                        </Col>
                        <Col sm="6">
                        </Col>
                    </Row>
                </ContentHeader>
                <ContentBody>
                    <SpinnerOverlay
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        error={error}
                    >
                        { renderSites(sites) }
                    </SpinnerOverlay>
                </ContentBody>
            </ContextDashboard>
        </>
    );
};

export default SiteListing;