import React, { FC } from "react";
import { Variant } from "../PropTypes";

interface Props {
    className?: string;
    variant?: Variant;
    outline?: boolean;
}

const Card: FC<Props> = ({
    children,
    className = "",
    variant = "primary",
    outline = true,
    ...restProps
}) => {
    return (
        <div
            className={`card 
                        ${variant ? `card-${variant}` : ""}  
                        ${outline ? "card-outline" : ""} 
                        ${className}`}
            {...restProps}
        >
            {children}
        </div>
    );
};

export default Card;