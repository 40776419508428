import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { Col, Row } from "react-bootstrap";

import ContextDashboard from "../../templates/dashboard/ContextDashboard";

import ContentHeader from "../../components/templates/ContentHeader";
import ContentBody from "../../components/templates/ContentBody";
import TestRunListingTable from "./TestRunListingTable";
import TestRunListingChart from "./TestRunListingChart";
import { DateTimeRangesEnum, dateTimeRangesEnumKeys, dateTimeRangesProvider } from "../../services/Enums";
import { useGetTestRunsQuery, useRefetchTestRunsMutation } from "../../services/TestRuns";
import AppButton from "../../components/buttons/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
    TestRunListingFilter,
    testRunListingUpdateDateTimeRange,
    testRunListingUpdateFilter,
} from "../../states/TestRunListing";
import { maxPageSize } from "../../const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TestRunListing: FC = () => {

    const dispatch = useDispatch();

    const { probeId } = useParams();

    const filter = useSelector((state: RootState) => state.testRunListing.filter);

    const selectedDateTimeRange = useSelector((state: RootState) => state.testRunListing.dateTimeRange);

    const { isLoading, isFetching, isSuccess, isError,
            data, error } = useGetTestRunsQuery({ probeId, dateTimeRange: selectedDateTimeRange, size: maxPageSize });

    const [
        refetchTestRuns,
    ] = useRefetchTestRunsMutation();

    function renderDateTimeRangeSelectorItem(dateTimeRange: DateTimeRangesEnum) {
        return (
            <AppButton
                key={`${dateTimeRange}`}
                variant="outline-primary"
                type="button"
                className={`mr-1 ${dateTimeRange === selectedDateTimeRange ? "active" : ""}`}
                size="xs"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(testRunListingUpdateDateTimeRange({ dateTimeRange }));
                }}
            >
                { dateTimeRangesProvider[`${dateTimeRange}`].shortLabel }
            </AppButton>
        );
    }

    function renderDateTimeRangeSelector() {
        return (
            <>
                {
                    dateTimeRangesEnumKeys.map((dateTimeRange) =>
                        renderDateTimeRangeSelectorItem(dateTimeRange))
                }
                <AppButton
                    key="refetch"
                    variant="outline-primary"
                    type="button"
                    className="mr-1"
                    size="xs"
                    onClick={(e) => {
                        e.preventDefault();
                        refetchTestRuns();
                    }}
                >
                    <FontAwesomeIcon fixedWidth icon="sync" />
                </AppButton>
            </>
        );
    }

    function renderFilterButton(filter: TestRunListingFilter, selectedFilter: "passed" | "failed" | "skipped" | "ignored" | "unknown", filterLabel: string) {
        return (
            <AppButton
                key={selectedFilter}
                variant="outline-primary"
                type="button"
                className={`mr-2 ${filter[selectedFilter] ? "active" : ""}`}
                size="xs"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(testRunListingUpdateFilter({
                        filter: selectedFilter,
                        visible: !filter[selectedFilter],
                    }));
                }}
            >
                {filterLabel}
            </AppButton>
        );
    }

    return (
        <>
            <ContextDashboard>
                <ContentHeader>
                    <Row>
                        <Col sm="6">
                            <h2 className="float-left mr-5">Test Runs</h2>
                        </Col>
                        <Col sm="6" className="text-right">
                            { probeId && renderDateTimeRangeSelector() }
                        </Col>
                    </Row>
                </ContentHeader>
                <ContentBody>
                    <Row className="mb-2">
                        <Col sm="12">
                            {
                                probeId &&
                                <TestRunListingChart
                                    isLoading={isLoading}
                                    isFetching={isFetching}
                                    isSuccess={isSuccess}
                                    isError={isError}
                                    data={data}
                                    error={error}
                                />
                            }
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="12" className="text-center">
                            { probeId && renderFilterButton(filter, "passed", "Passed") }
                            { probeId && renderFilterButton(filter, "failed", "Failed") }
                            { probeId && renderFilterButton(filter, "skipped", "Skipped") }
                            { probeId && renderFilterButton(filter, "ignored", "Ignored") }
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col sm="12">
                            {
                                probeId &&
                                <TestRunListingTable
                                    isLoading={isLoading}
                                    isFetching={isFetching}
                                    isSuccess={isSuccess}
                                    isError={isError}
                                    data={data}
                                    error={error}
                                />
                            }
                        </Col>
                    </Row>
                </ContentBody>
            </ContextDashboard>
        </>
    );
};

export default TestRunListing;