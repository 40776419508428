import React, { FC, ReactNode, useEffect, useState } from "react";
import { Probe } from "../../services/Models";
import ProbeHexCell from "./ProbeHexCell";

interface Props {
    probes: Probe[];
}

const ProbeHexGrid: FC<Props> = ({
    probes,
}) => {

    function calculateGridLayout(probes: Probe[]) {
        return {
            total: probes.length,
            avgRowWidth: 9.5, // (4 / 3) * Math.sqrt(probes.length),
            maxRowWidth: 10, // Math.ceil((4 / 3) * Math.sqrt(probes.length)),
            minRowWidth: 9, // Math.floor((4 / 3) * Math.sqrt(probes.length)),
            rowCount: Math.ceil(probes.length / 9.5), // Math.ceil(probes.length / ((4 / 3) * Math.sqrt(probes.length))),
        };
    }

    const [
        {
            total,
            // avgRowWidth,
            maxRowWidth,
            minRowWidth,
            rowCount,
        },
        setGridLayout,
    ] = useState(calculateGridLayout(probes));

    useEffect(() => {
        setGridLayout(calculateGridLayout(probes));
    }, [ probes ]);

    function isEven(rowIndex: number): boolean {
        return ((rowIndex + 1) % 2) === 0;
    }

    function calcRowWidth(rowIndex: number) {
        return isEven(rowIndex) ? minRowWidth : maxRowWidth;
    }

    function renderRow(rowIndex: number, offset: number, probes: Probe[]): React.ReactNode {

        const rowWidth = calcRowWidth(rowIndex);

        const cells: ReactNode[] = [];

        for (let cellIndex = 0;
             (cellIndex < rowWidth) && ((offset + cellIndex) < total);
             cellIndex++) {
            const probeIndex = offset + cellIndex;
            cells.push(<ProbeHexCell key={probeIndex} probe={probes[probeIndex]} />);
        }

        return (
            <div key={rowIndex} className="hexagon-grid-row">
                { cells }
            </div>
        );

    }

    function renderGrid(probes: Probe[]): ReactNode {

        let offset = 0;

        const rows: ReactNode[] = [];

        for (let rowIndex = 0;
             rowIndex < rowCount && offset < total;
             rowIndex ++) {

            rows.push(renderRow(rowIndex, offset, probes));
            offset += calcRowWidth(rowIndex);

        }

        return rows;

    }

    return (
        <div className="hexagon-grid">
            { renderGrid(probes) }
        </div>
    );

};

export default ProbeHexGrid;