import React, { FC, ReactNode } from "react";
import { useGetTestRunQuery } from "../../services/TestRuns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import CollapsibleCard from "../../components/templates/CollapsibleCard";
import {
    isTestRunFeatureBackground,
    isTestRunFeatureElementStepFailed,
    isTestRunFeatureElementStepOther,
    isTestRunFeatureElementStepSkipped,
    isTestRunFeatureScenario,
    TestRunFeature,
    TestRunFeatureElement,
    TestRunFeatureElementStep,
    TestRunState,
} from "../../services/Models";
import { Variant } from "../../components/PropTypes";

interface Props {
    probeId: string;
    testRunStateId: string;
}

const TestRunReport: FC<Props> = ({ probeId, testRunStateId }) => {

    const { isLoading, isFetching, isSuccess, isError,
            data: testRun, error } = useGetTestRunQuery({ probeId, testRunStateId });

    const renderCardTheme = (hasFailed: boolean, hasSkipped: boolean, hasUnknown: boolean): Variant => {
        return hasFailed ? "danger"
             : hasSkipped ? "warning"
             : hasUnknown ? "info"
             : "success";
    };

    const renderStatusIcons = (hasFailed: boolean, hasSkipped: boolean, hasUnkown: boolean) => {
        return hasFailed ? <FontAwesomeIcon key="icon-failed" className="text-danger mr-2" icon="times-circle" />
             : hasSkipped ? <FontAwesomeIcon key="icon-skipped" className="text-warning mr-2" icon="chevron-circle-right" />
             : hasUnkown ? <FontAwesomeIcon key="icon-unknown" className="text-info mr-2" icon="question-circle" />
             : <FontAwesomeIcon key="icon-success" className="text-success mr-2" icon="check-circle" />;
    };

    const stepHasFailed = isTestRunFeatureElementStepFailed;
    const stepHasSkipped = isTestRunFeatureElementStepSkipped;
    const stepHasUnknown = isTestRunFeatureElementStepOther;

    function formatStepNameExample(name: string | ReactNode, exampleValue: string): ReactNode[] {
        if (typeof name !== "string") return [ name ];
        if (!name) return [ "" ];

        const strArr = name.split(new RegExp(`(${exampleValue})`, "g"));
        return strArr.map((ea, i) => {
            return ea === exampleValue ? <strong key={`match${i}`}>{ea}</strong>
                 : ea;
        });
    }

    const formatStepNameExamples = (step: TestRunFeatureElementStep): ReactNode[] => {
        const name: string = step?.name;
        const exampleValues: string[] = step?.match?.arguments?.map(argument => argument?.val);

        if (!name) return [ "" ];
        if (!exampleValues) return [ name ];

        let formattedName: ReactNode[] = [ name ];

        for (const exampleValue of exampleValues) {
            let tempFormattedName: ReactNode[] = [];
            for (const namePart of formattedName) {
                tempFormattedName = tempFormattedName.concat(formatStepNameExample(namePart, exampleValue));
            }
            formattedName = tempFormattedName;
        }

        return formattedName;
    };

    const renderElementStep = (step: TestRunFeatureElementStep, stepIdx: number) => {
        const hasFailed = stepHasFailed(step);
        const hasSkipped = stepHasSkipped(step);
        const hasUnknown = stepHasUnknown(step);

        const stepKey = step?.keyword ? `${step?.keyword}-${stepIdx}` : `${stepIdx}`;

        return step && (
            <CollapsibleCard
                key={stepKey}
                title={ <span key="title-wrapper">
                            { renderStatusIcons(hasFailed, hasSkipped, hasUnknown) }
                            <strong key={stepKey}>{ `${step?.keyword} ` }</strong>
                            { formatStepNameExamples(step) }
                        </span> }
                variant={renderCardTheme(hasFailed, hasSkipped, hasUnknown)}
            >
                {hasFailed && (
                    <pre className="text-danger">{step?.result?.error_message}</pre>
                )}
            </CollapsibleCard>
        );
    };

    const renderElementSteps = 
        (element: TestRunFeatureElement) => element?.steps?.map(renderElementStep);


    const elementHasFailedSteps =
        (element:TestRunFeatureElement) =>
            element?.steps?.find(isTestRunFeatureElementStepFailed) !== undefined;

    const elementHasSkippedSteps =
        (element: TestRunFeatureElement) =>
            element?.steps?.find(isTestRunFeatureElementStepSkipped) !== undefined;

    const elementHasUnkownSteps =
        (element: TestRunFeatureElement) =>
            element?.steps?.find(isTestRunFeatureElementStepOther) !== undefined;

    const renderFeatureElement = (element: TestRunFeatureElement, elementIdx: number) => {
        const hasFailed = elementHasFailedSteps(element);
        const hasSkipped = elementHasSkippedSteps(element);
        const hasUnknown = elementHasUnkownSteps(element);

        const elementKey = isTestRunFeatureBackground(element) || isTestRunFeatureScenario(element)
                         ? (element?.id ? element?.id
                           : element?.type ? `${element?.type}-${elementIdx}`
                           : `${elementIdx}`)
                         : elementIdx;

        return element && (
            <CollapsibleCard
                key={elementKey}
                title={ <span key="title-wrapper">
                            { renderStatusIcons(hasFailed, hasSkipped, hasUnknown) }
                            <strong key={elementKey}>{ `${element?.keyword}: ` }</strong>
                            { `${element?.name}` }
                        </span> }
                variant={renderCardTheme(hasFailed, hasSkipped, hasUnknown)}
            >
                { renderElementSteps(element) }
            </CollapsibleCard>
        );
    };

    const renderFeatureElements =
        (feature: TestRunFeature) => feature?.elements?.map(renderFeatureElement);


    const featureHasFailedSteps =
        (feature: TestRunFeature) =>
            feature?.elements?.flatMap(element => element?.steps)?.find(isTestRunFeatureElementStepFailed) !== undefined;

    const featureHasSkippedSteps =
        (feature: TestRunFeature) =>
            feature?.elements?.flatMap(element => element?.steps)?.find(isTestRunFeatureElementStepSkipped) !== undefined;

    const featureHasUnknownSteps =
        (feature: TestRunFeature) =>
            feature?.elements?.flatMap(element => element?.steps)?.find(isTestRunFeatureElementStepOther) !== undefined;

    const renderReportFeature = (feature: TestRunFeature, featureIdx: number) => {
        const hasFailed = featureHasFailedSteps(feature);
        const hasSkipped = featureHasSkippedSteps(feature);
        const hasUnkown = featureHasUnknownSteps(feature);

        const featureKey = feature?.id ? feature?.id
                         : `${feature?.type ? feature?.type 
                         : feature?.keyword}-${featureIdx}`;

        return feature && (
            <CollapsibleCard
                key={featureKey}
                title={ <span key="title-wrapper">
                            { renderStatusIcons(hasFailed, hasSkipped, hasUnkown) }
                            <strong key={featureKey}>Feature: </strong>
                            { `${feature?.name}` }
                        </span> }
                variant={renderCardTheme(hasFailed, hasSkipped, hasUnkown)}
            >
                { renderFeatureElements(feature) }
            </CollapsibleCard>
        );
    };

    const renderReportFeatures =
        (testRun: TestRunState) =>
            testRun?.report?.map(renderReportFeature);


    return (
        <>
            <SpinnerOverlay
                isLoading={isLoading}
                isFetching={isFetching}
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            >
            { testRun ? (
                <>
                { renderReportFeatures(testRun) }
                </>
            ) : null }
            </SpinnerOverlay>
        </>
    );

};

export default TestRunReport;