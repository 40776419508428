import React, { FC, useMemo } from "react";
import { useGetProbesQuery } from "../../services/Probes";
import { maxPageSize } from "../../const";
import ContextDashboard from "../../templates/dashboard/ContextDashboard";
import ContentBody from "../../components/templates/ContentBody";
import { Col, Row } from "react-bootstrap";
import ProbeHexGrid from "./ProbeHexGrid";
import ContentHeader from "../../components/templates/ContentHeader";

const ProbeStatusDashboard: FC = () => {

    const { /*isLoading, isFetching, isSuccess, isError,*/
        data,
        /*, error*/
    } = useGetProbesQuery({ size: maxPageSize });

    const probes = useMemo(() => data?.items || [], [ data ]);

    return (
        <>
            <ContextDashboard>
                <ContentHeader/>
                <ContentBody>
                    <Row>
                        <Col sm="2"/>
                        <Col sm="8">
                            <ProbeHexGrid probes={probes} />
                        </Col>
                        <Col sm="2"/>
                    </Row>
                </ContentBody>
            </ContextDashboard>
        </>
    );

};

export default ProbeStatusDashboard;