import React, { FC, useEffect, useMemo, useState } from "react";

import {
    Chart as ChartJS,
    ChartData,
    ChartEvent,
    ChartOptions,
    LegendElement,
    LegendItem,
} from "chart.js";
import {
    Chart,
} from "react-chartjs-2";
import moment from "moment";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import { AxiosQueryError } from "../../services/CpwpApi";
import { Paged, TestRunState } from "../../services/Models";
import { testRunListingUpdateFilter } from "../../states/TestRunListing";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
    isLoading?: boolean;
    isFetching?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    data?: Paged<TestRunState>;
    error?: AxiosQueryError | unknown;
}

const TestRunListingChart: FC<Props> = ({
    isLoading = false,
    isFetching = false,
    isSuccess = false,
    isError = false,
    data = undefined,
    error = undefined,
}) => {

    const dispatch = useDispatch();

    const items = useMemo(() => data?.items ? data?.items : [], [ data ]);

    const filter = useSelector((state: RootState) => state.testRunListing.filter);

    const options: ChartOptions<"bar"> = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
                onClick: (e: ChartEvent, legendItem: LegendItem, legend: LegendElement<"bar">) => {
                    ChartJS.defaults.plugins.legend.onClick.apply(legend, [ e, legendItem, legend ]);
                    const selectedFilter = legendItem?.text?.toLocaleLowerCase() || "";
                    if (selectedFilter === "passed" ||
                        selectedFilter === "failed" ||
                        selectedFilter === "skipped" ||
                        selectedFilter === "ignored") {

                        dispatch(testRunListingUpdateFilter({
                            filter: selectedFilter,
                            visible: !legendItem?.hidden,
                        }));

                    } else {
                        dispatch(testRunListingUpdateFilter({
                            filter: "unknown",
                            visible: !legendItem?.hidden,
                        }));
                    }
                },
            },
            title: {
                display: false,
                text: "",
            },
        },
        scales: {
            xAxis: {
                type: "time",
                time: {
                    displayFormats: {
                        minute: "HH:mm",
                        hour: "DD MMM HH:mm",
                    },
                    round: "minute",
                },
                stacked: true,
                grid: {
                    display : false,
                },
            },
            yAxis: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
                grid: {
                    display : false,
                },
            },
        },
    };

    const [ chartData, setChartData ] = useState<ChartData<"bar">>({ datasets:[] });

    useEffect(() => {
        setChartData({
            labels: items?.map((testRun) => +moment(testRun.scheduled)),
            datasets: [
                {
                    type: "bar",
                    label: "Passed",
                    backgroundColor: "rgb(40,167,69,0.9)",
                    borderColor: "rgb(40,167,69,0.9)",
                    data: items?.map((testRun) => testRun.count - (testRun.failureCount + testRun.ignoreCount + testRun.assumptionFailureCount)),
                    hidden: !filter?.passed,
                },
                {
                    type: "bar",
                    label: "Failed",
                    backgroundColor: "rgb(220,53,69,0.8)",
                    borderColor: "rgb(220,53,69,0.8)",
                    data: items?.map((testRun) => testRun.failureCount),
                    hidden: !filter?.failed,
                },
                {
                    type: "bar",
                    label: "Skipped",
                    backgroundColor: "rgb(255,193,7,0.7)",
                    borderColor: "rgb(255,193,0.7)",
                    data: items?.map((testRun) => testRun.assumptionFailureCount),
                    hidden: !filter?.skipped,
                },
                {
                    type: "bar",
                    label: "Ignored",
                    backgroundColor: "rgb(108,117,125,0.6)",
                    borderColor: "rgb(108,117,125,0.6)",
                    data: items?.map((testRun) => testRun.ignoreCount),
                    hidden: !filter?.ignored,
                },
             ],
        });
    },
    [ items, data, filter?.passed, filter?.failed, filter?.skipped, filter?.ignored ]);

    return (
        <>
            <SpinnerOverlay
                isLoading={isLoading}
                isFetching={isFetching}
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            >
                <div className="chart" style={{ height: 250, maxHeight: 250 }}>
                    <Chart type="bar" options={options} data={chartData} style={{ height: 250, maxHeight: 250 }} />
                </div>
            </SpinnerOverlay>
        </>
    );

};

export default TestRunListingChart;