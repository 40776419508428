import React, { FC } from "react";
import { useGetProbeQuery } from "../../services/Probes";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../templates/ContentHeader";
import ContentBody from "../templates/ContentBody";
import SpinnerOverlay from "../templates/SpinnerOverlay";

import LinkButton from "../buttons/LinkButton";

interface Props {
    probeId: string;
    basePath?: string;
}

const ContextSideBarProbe: FC<Props> = ({
    probeId,
    basePath = "",
}) => {

    const { isLoading, isFetching, isSuccess, isError,
            data: probe, error } = useGetProbeQuery({ probeId });

    return (
        <>
            <SpinnerOverlay
                isLoading={isLoading}
                isFetching={isFetching}
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            >
                { probe ? (
                    <>
                        <ContentHeader>
                            <Row>
                                <Col sm="5">
                                    <h3 className="float-left mr-5">Probe</h3>
                                </Col>
                                <Col sm="1">
                                    <LinkButton
                                        key="edit-tenant"
                                        variant="tool"
                                        icon="pencil-alt"
                                        to={`${basePath}../${probe.probeId}/edit`}
                                    />
                                </Col>
                                <Col sm="3">
                                    <LinkButton
                                        variant="outline-primary"
                                        icon="chevron-up"
                                        to={`${basePath}..`}
                                        size="xs"
                                        className="btn-block"
                                    >
                                        Probes
                                    </LinkButton>
                                </Col>
                            </Row>
                        </ContentHeader>
                        <ContentBody>
                            <Row className="mb-2">
                                <Col sm="12">
                                    <dl className="dl-sm mb-1">
                                        <dd>{probe?.probeId}</dd>
                                        <dt>Name</dt>
                                        <dd>{probe?.name}</dd>
                                        <dt>Model</dt>
                                        <dd>{probe?.model}</dd>
                                        <dt>Serial Number</dt>
                                        <dd>{probe?.serialNumber}</dd>
                                        <dt>DNS</dt>
                                        <dd>{probe?.dns}</dd>
                                        <dt>VPN IP (IPv4)</dt>
                                        <dd>{probe?.vpnIpV4 ? probe?.vpnIpV4 : "-"}</dd>
                                        <dt>VPN IP (IPv6)</dt>
                                        <dd>{probe?.vpnIpV6 ? probe?.vpnIpV6 : "-"}</dd>
                                    </dl>

                                </Col>
                            </Row>
                        </ContentBody>
                    </>
                ) : null }
            </SpinnerOverlay>
        </>
    );

};

export default ContextSideBarProbe;