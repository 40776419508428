import React, { FC, useMemo } from "react";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../../components/templates/ContentHeader";
import ContentBody from "../../components/templates/ContentBody";

import Card from "../../components/templates/Card";
import CardHeader from "../../components/templates/CardHeader";

import LinkButton from "../../components/buttons/LinkButton";
import CardTools from "../../components/templates/CardTools";
import ContextDashboard from "../../templates/dashboard/ContextDashboard";
import { useGetProbesQuery } from "../../services/Probes";
import { Probe } from "../../services/Models";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import { maxPageSize } from "../../const";
import { useParams } from "react-router-dom";

const ProbeListing: FC = () => {

    const { siteId } = useParams();

    const { isLoading, isFetching, isSuccess, isError,
        data, error } = useGetProbesQuery({ siteId, size: maxPageSize });

    const probes = useMemo(() => data?.items || [], [ data ]);

    const renderProbeCard = (probe: Probe) => {
        return (
            <Card key={probe.probeId}>
                <CardHeader title={probe.name} linkTo={`${probe.probeId}`}>
                    <CardTools>
                        <LinkButton
                            variant="tool"
                            icon="pencil-alt"
                            to={`${probe.probeId}/edit`}
                        />
                    </CardTools>
                </CardHeader>
            </Card>
        );
    };

    const renderProbes = (probes: Probe[]) => {
        return (
            <Row>
                <Col sm="12">
                    { probes.map(renderProbeCard) }
                </Col>
            </Row>
        );
    };

    return (
        <>
            <ContextDashboard>
                <ContentHeader>
                    <Row>
                        <Col sm="6">
                            <h1 className="float-left mr-5">Probes</h1>
                            <LinkButton
                                variant="outline-primary"
                                icon="plus"
                                to="0/edit"
                            >
                                Add Probe
                            </LinkButton>
                        </Col>
                        <Col sm="6">
                            {/*<ol className="breadcrumb float-sm-right">*/}
                            {/*    <li className="breadcrumb-item">*/}
                            {/*        <a href="/">Home</a>*/}
                            {/*    </li>*/}
                            {/*    <li className="breadcrumb-item active">{title}</li>*/}
                            {/*</ol>*/}
                        </Col>
                    </Row>
                </ContentHeader>
                <ContentBody>
                    <SpinnerOverlay
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        error={error}
                    >
                        { renderProbes(probes) }
                    </SpinnerOverlay>
                </ContentBody>
            </ContextDashboard>
        </>
    );
};

export default ProbeListing;