import React, { FC } from "react";
import { useGetTestRunQuery } from "../../services/TestRuns";
import SpinnerOverlay from "../templates/SpinnerOverlay";
import ContentHeader from "../templates/ContentHeader";
import { Col, Row } from "react-bootstrap";
import LinkButton from "../buttons/LinkButton";
import ContentBody from "../templates/ContentBody";
import Moment from "react-moment";
import Badge from "../elements/Badge";

interface Props {
    probeId: string;
    testRunStateId: string;
    basePath?: string;
}

const ContextSideBarTestRun: FC<Props> = ({
    probeId,
    testRunStateId,
    basePath = "",
}) => {

    const {
        isLoading,
        isFetching,
        isSuccess,
        isError,
        data: testRun,
        error,
    } = useGetTestRunQuery({ probeId, testRunStateId });

    return (
        <>
            <SpinnerOverlay
                isLoading={isLoading}
                isFetching={isFetching}
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            >
                {testRun ? (
                    <>
                        <ContentHeader>
                            <Row>
                                <Col sm="6">
                                    <h3 className="float-left mr-5">
                                        Test Run
                                    </h3>
                                </Col>
                                <Col sm="3">
                                    <LinkButton
                                        variant="outline-primary"
                                        icon="chevron-up"
                                        to={`${basePath}..`}
                                        size="xs"
                                        className="btn-block"
                                    >
                                        Test Runs
                                    </LinkButton>
                                </Col>
                            </Row>
                        </ContentHeader>
                        <ContentBody>
                            <Row className="mb-2">
                                <Col sm="12">
                                    <dl className="dl-sm mb-1">
                                        <dd>{testRun?.id}</dd>
                                        <dt>Scheduled</dt>
                                        <dd>
                                            <Moment
                                                date={testRun.scheduled}
                                                format="YYYY-MM-DD HH:mm:SS"
                                                interval={0}
                                            />
                                        </dd>
                                        <dt>Started</dt>
                                        <dd>
                                            <Moment
                                                date={testRun.started}
                                                format="YYYY-MM-DD HH:mm:SS"
                                                interval={0}
                                            />
                                        </dd>
                                        <dt>Completed</dt>
                                        <dd>
                                            <Moment
                                                date={testRun.completed}
                                                format="YYYY-MM-DD HH:mm:SS"
                                                interval={0}
                                            />
                                        </dd>
                                    </dl>
                                    <Row>
                                        <Col sm="1">
                                            <Badge variant="success">
                                                {testRun?.count -
                                                    (testRun?.failureCount +
                                                        testRun?.ignoreCount +
                                                        testRun?.assumptionFailureCount)}
                                            </Badge>
                                        </Col>
                                        <Col sm="1">
                                            <Badge variant="danger">
                                                {testRun?.failureCount}
                                            </Badge>
                                        </Col>
                                        <Col sm="1">
                                            <Badge variant="warning">
                                                {
                                                    testRun?.assumptionFailureCount
                                                }
                                            </Badge>
                                        </Col>
                                        <Col sm="1">
                                            <Badge variant="dark">
                                                {testRun?.ignoreCount}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </ContentBody>
                    </>
                ) : null}
            </SpinnerOverlay>
        </>
    );
};

export default ContextSideBarTestRun;
