import React, { forwardRef, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { ButtonVariant, isFontAwesomeIconProps, Size } from "../PropTypes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
    className?: string;
    variant?: ButtonVariant;
    size?: Size;
    icon?: IconProp | FontAwesomeIconProps;
    to: string;
    onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

// eslint-disable-next-line react/display-name
const LinkButton = forwardRef<HTMLAnchorElement, PropsWithChildren<Props>>(({
    children,
    className = "",
    variant = "primary",
    size = "md",
    icon,
    to,
    onClick = () => { return; },
    ...restProps
}, ref) => {
    let iconTemplate;

    if (icon) {
        const iconProps: FontAwesomeIconProps = isFontAwesomeIconProps(icon)
            ? icon
            : { icon: icon, size: "sm" };

        iconTemplate = (
            <FontAwesomeIcon fixedWidth {...iconProps} />
        );
    }

    return (
        <Link
            {...restProps}
            ref={ref}
            to={to}
            className={`btn 
                       ${variant ? `btn-${variant}` : ""} 
                       ${size ? `btn-${size}` : ""} 
                       ${className}`}
            onClick={onClick}
        >
            {iconTemplate}
            {children}
        </Link>
    );
});

export default LinkButton;
