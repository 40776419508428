import React, { FC, useEffect, useMemo, useState } from "react";
import { CellProps, Column, useTable } from "react-table";
import Moment from "react-moment";

import Card from "../../components/templates/Card";
import CardBody from "../../components/templates/CardBody";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import LinkButton from "../../components/buttons/LinkButton";
import Badge from "../../components/elements/Badge";
import { Paged, TestRunState } from "../../services/Models";
import { AxiosQueryError } from "../../services/CpwpApi";
import { useInView } from "react-intersection-observer";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface Props {
    isLoading?: boolean;
    isFetching?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    data?: Paged<TestRunState>;
    error?: AxiosQueryError | unknown;
}

const TestRunListingTable: FC<Props> = ({
    isLoading = false,
    isFetching = false,
    isSuccess = false,
    isError = false,
    data = undefined,
    error = undefined,
}) => {

    function passedCount(testRun: TestRunState) {
        return testRun.count - (testRun.failureCount + testRun.ignoreCount + testRun.assumptionFailureCount);
    }

    function failedCount(testRun: TestRunState) {
        return testRun.failureCount;
    }

    function skippedCount(testRun: TestRunState) {
        return testRun.assumptionFailureCount;
    }

    function ignoredCount(testRun: TestRunState) {
        return testRun.ignoreCount;
    }


    const items = useMemo(() => data?.items ? data?.items : [], [ data ]);


    const filter = useSelector((state: RootState) => state.testRunListing.filter);

    const [ filteredItems, setFilteredItems ] = useState(items);

    useEffect(() => {
        setFilteredItems(
            items?.filter(testRun => (passedCount(testRun) > 0 && filter.passed)
                                  || (failedCount(testRun) > 0 && filter.failed)
                                  || (skippedCount(testRun) > 0 && filter.skipped)
                                  || (ignoredCount(testRun) > 0 && filter.ignored)));
    }, [ items, filter ]);


    const selectedSize = 200;

    const [ pageMetaData, setPageMetaData ] =
        useState({
            size: selectedSize,
            totalElements: data?.page?.totalElements || 0,
            totalPages: data?.page?.totalElements ? Math.ceil(data?.page?.totalElements / selectedSize) : 0,
            number: data?.page?.number || 0,
        });

    useEffect(() => {
        setPageMetaData({
            size: selectedSize,
            totalElements: data?.page?.totalElements || 0,
            totalPages: data?.page?.totalElements ? Math.ceil(data?.page?.totalElements / selectedSize) : 0,
            number: data?.page?.number || 0,
        });
    }, [ data?.page?.number, data?.page?.totalElements ]);


    const [ hasMorePages, setHasMorePages ] = useState(true);

    useEffect(() => {
        console.debug("pageMetaData", pageMetaData);

        if (pageMetaData.number < (pageMetaData.totalPages - 1)) {
            setHasMorePages(true);
        } else {
            setHasMorePages(false);
        }
    }, [ pageMetaData, pageMetaData.number, pageMetaData.totalPages ]);


    const [ displayItems, setDisplayItems ] =
        useState(filteredItems.slice(0, (pageMetaData.number + 1) * pageMetaData.size));

    useEffect(() => {
        setDisplayItems(filteredItems.slice(0, (pageMetaData.number + 1) * pageMetaData.size));
    }, [ filteredItems, pageMetaData.number, pageMetaData.size ]);


    const { ref, inView } = useInView({
        threshold: 1.0,
    });

    useEffect(() => {
        if (inView) {
            setPageMetaData(prevState => ({
                ...prevState,
                number: prevState.number + 1,
            }));
        }
    }, [ inView ]);


    const columns: ReadonlyArray<Column<TestRunState>> = useMemo(() => [
        {
            id: "scheduled",
            Header: "Scheduled",
            accessor: d => d,
            Cell: (d: CellProps<TestRunState, TestRunState>) => {
                const testRun = d.value;
                const testRunStatusBgClass = typeof testRun?.successful === "undefined" || testRun?.successful === true ? "outline-primary"
                                           : testRun?.failureCount === 0 && testRun?.assumptionFailureCount > 0 ? "warning"
                                           : "danger";
                return (
                    <LinkButton variant={`${testRunStatusBgClass}`} to={`${testRun?.id || ""}/report`}>
                        <Moment date={testRun.scheduled} format="YYYY-MM-DD HH:mm" interval={0} />
                    </LinkButton>
                );
            },
            width: 250,
        },
        {
            id: "testRunStateId",
            Header: "Results",
            accessor: d => d,
            Cell: (d: CellProps<TestRunState, TestRunState>) => {
                const testRun = d.value;

                const count = testRun.count;
                const failureCount = testRun.failureCount;
                const ignoreCount = testRun.ignoreCount;
                const assumptionFailureCount = testRun.assumptionFailureCount;

                const successPercentage = ((count - (failureCount + ignoreCount + assumptionFailureCount)) / (count)) * 100;
                const failurePercentage = (failureCount / (count)) * 100;
                const assumptionFailurePercentage = (assumptionFailureCount / (count)) * 100;

                return (
                    <div className="progress progress mt-2">
                        <div className="progress-bar bg-success" style={{ width: `${successPercentage}%` }} />
                        <div className="progress-bar bg-danger" style={{ width: `${failurePercentage}%` }}/>
                        <div className="progress-bar bg-warning" style={{ width: `${assumptionFailurePercentage}%` }}/>
                    </div>
                );
            },
            width: "auto",
        },
        {
            id: "passedCount",
            Header: "Passed",
            accessor: d => d,
            Cell: (d: CellProps<TestRunState, TestRunState>) => {
                const testRun = d.value;
                return <Badge variant="success">{ passedCount(testRun) }</Badge>;
            },
            width: 50,
        },
        {
            id: "failedCount",
            Header: "Failed",
            accessor: d => d,
            Cell: (d: CellProps<TestRunState, TestRunState>) => {
                const testRun = d.value;
                return <Badge variant="danger">{ failedCount(testRun) }</Badge>;
            },
            width: 50,
        },
        {
            id: "skippedCount",
            Header: "Skipped",
            accessor: d => d,
            Cell: (d: CellProps<TestRunState, TestRunState>) => {
                const testRun = d.value;
                return <Badge variant="warning">{ skippedCount(testRun) }</Badge>;
            },
            width: 50,
        },
        {
            id: "ignoredCount",
            Header: "Ignored",
            accessor: d => d,
            Cell: (d: CellProps<TestRunState, TestRunState>) => {
                const testRun = d.value;
                return <Badge variant="dark">{ ignoredCount(testRun) }</Badge>;
            },
            width: 50,
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: displayItems });

    const renderTestRunListing = () => {
        return (
            <Card>
                <CardBody className="p-0">
                    <table className="table" {...getTableProps()}>
                        <thead>
                        { headerGroups.map(headerGroup => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

                            return (
                                <tr key={key} {...headerGroupProps}>
                                    {headerGroup.headers.map(column => {
                                        const { key, ...columnProps } =
                                            column.getHeaderProps({
                                                style: { width: column.width },
                                            });
                                        return (
                                            <th key={key} {...columnProps}>
                                                {column.render("Header")}
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        { rows.map(row => {
                            prepareRow(row);
                            const testRun = row?.original;
                            const testRunStatusBgClass = typeof testRun?.successful === "undefined" || testRun?.successful === true ? ""
                                                     : testRun?.failureCount === 0 && testRun?.assumptionFailureCount > 0 ? "bg-gradient-warning subdued"
                                                     : "bg-gradient-danger subdued";
                            const { key, ...rowProps } = row.getRowProps();
                            return (
                                <tr key={key} className={testRunStatusBgClass} {...rowProps}>
                                    { row.cells.map(cell => {
                                        const { key, ...cellProps } = cell.getCellProps();
                                        return (
                                            <td key={key} {...cellProps} className="text-center">
                                            { cell.render("Cell") }
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    {
                        hasMorePages &&
                        <Row>
                            <Col sm="12">
                                <div ref={ref}>
                                    <SpinnerOverlay
                                        isLoading
                                        isFetching
                                    >
                                    </SpinnerOverlay>
                                </div>
                            </Col>
                        </Row>
                    }
                </CardBody>
            </Card>
        );
    };

    return (
        <>
            <SpinnerOverlay
                isLoading={isLoading}
                isFetching={isFetching}
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            >
            { renderTestRunListing() }
            </SpinnerOverlay>
        </>
    );
};

export default TestRunListingTable;