import React, { FC, forwardRef, Ref, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Probe, Paged, Site } from "../../services/Models";
import { useGetProbesQuery, useLinkSiteProbeMutation, useUnlinkSiteProbeMutation } from "../../services/Probes";
import LinkButton from "../../components/buttons/LinkButton";
import CollapsibleCard from "../../components/templates/CollapsibleCard";
import { maxPageSize } from "../../const";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import Callout from "../../components/templates/Callout";
import { ButtonGroup, Col, Dropdown, Row } from "react-bootstrap";
import AppButton from "../../components/buttons/AppButton";

interface Props {
    site: Site;
}

const SiteListingCard: FC<Props> = ({
    site,
}) => {

    const {
        isLoading: isProbesLoading,
        isFetching: isProbesFetching,
        isSuccess: isProbesSuccess,
        isError: isProbesError,
        data: probesData,
        error: probesError,
    } = useGetProbesQuery({ siteId: site.siteId, size: maxPageSize });

    const {
        isLoading: isAllProbesLoading,
        isFetching: isAllProbesFetching,
        isSuccess: isAllProbesSuccess,
        isError: isAllProbesError,
        data: allProbesData,
        error: allProbesError,
    } = useGetProbesQuery({ size: maxPageSize });

    const {
        data: availableProbesData,
    } = useMemo(() => ({
        data: _.differenceBy(allProbesData?.items || [], probesData?.items || [], "id"),
    }), [ allProbesData, probesData ]);

    const [
        linkSiteProbe,
        {
            isError: isSiteProbeLinkError,
            error: siteProbeLinkError,
        },
    ] = useLinkSiteProbeMutation();

    const [
        unlinkSiteProbe,
        {
            isError: isSiteProbeUnlinkError,
            error: siteProbeUnlinkError,
        },
    ] = useUnlinkSiteProbeMutation();

    const [ hasProbes, setHasProbes ] = useState((probesData?.items?.length || 0) > 0);

    useEffect(() => {
        setHasProbes((probesData?.items?.length || 0) > 0);
    }, [ probesData?.items?.length ]);

    type CustomToggleProps = {
        children?: React.ReactNode;
        onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
    };

    // eslint-disable-next-line react/display-name
    const CustomToggle = forwardRef((props: CustomToggleProps, ref: Ref<HTMLAnchorElement>) => (
        <LinkButton
            ref={ref}
            variant="outline-primary"
            size="xs"
            to=""
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            &#x25bc;
        </LinkButton>
    ));


    const renderCardTools = (site: Site) => {
        return (
            <>
                <Dropdown
                    as={ButtonGroup}
                    className="mr-5"
                >
                    <LinkButton
                        key="add-probe"
                        variant="outline-primary"
                        icon="plus"
                        size="xs"
                        to={`${site.siteId}/probes/0/edit`}
                    >
                        Add Probe
                    </LinkButton>

                    {
                        availableProbesData &&
                        <>
                            <Dropdown.Toggle
                                key="select-probe"
                                as={CustomToggle}
                                split
                            />

                            <Dropdown.Menu>
                                {
                                    availableProbesData.map((probe) => (
                                        <Dropdown.Item
                                            key={probe.probeId}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                linkSiteProbe({
                                                    siteId: site.siteId,
                                                    probeId: probe.probeId,
                                                });
                                            }}
                                        >
                                            {probe.name}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </>
                    }
                </Dropdown>

                <LinkButton
                    key="edit-site"
                    variant="tool"
                    icon="pencil-alt"
                    to={`${site.siteId}/edit`}
                />
            </>
        );
    };

    const renderProbe = (site: Site, probe: Probe) => {
        return (
            <Callout
                key={probe.probeId}
                variant="primary"
            >
                <Row>
                    <Col sm="10">
                        <LinkButton
                            key="view-probe"
                            variant="link"
                            to={`${site.siteId}/probes/${probe.probeId}`}
                        >
                            {probe.name}
                        </LinkButton>
                    </Col>
                    <Col sm="2" className="text-right">
                        <AppButton
                            variant="outline-primary"
                            icon="times"
                            size="xs"
                            onClick={(e) => {
                                e.preventDefault();
                                unlinkSiteProbe({
                                    siteId: site.siteId,
                                    probeId: probe.probeId,
                                });
                            }}
                        >
                            Remove
                        </AppButton>
                    </Col>
                </Row>
            </Callout>
        );
    };

    const renderProbes = (site: Site, probes: Paged<Probe> | undefined) => {
        return (
            probes && probes.items &&
            <>
                {probes.items.map((probe) => renderProbe(site, probe))}
            </>
        );
    };

    const renderSiteCard = (site: Site) => {
        return (
            <>
                <CollapsibleCard
                    title={site.name}
                    titleLinkTo={`${site.siteId}`}
                    cardTools={renderCardTools(site)}
                    variant="primary"
                    collapsible={hasProbes}
                >
                    <SpinnerOverlay
                        isLoading={isProbesLoading || isAllProbesLoading}
                        isFetching={isProbesFetching || isAllProbesFetching}
                        isSuccess={isProbesSuccess && isAllProbesSuccess}
                        isError={isProbesError || isAllProbesError || isSiteProbeLinkError || isSiteProbeUnlinkError}
                        error={probesError || allProbesError || siteProbeLinkError || siteProbeUnlinkError}
                    >
                        {renderProbes(site, probesData)}
                    </SpinnerOverlay>
                </CollapsibleCard>
            </>
        );
    };

    return (
        <>
            {renderSiteCard(site)}
        </>
    );
};

export default SiteListingCard;