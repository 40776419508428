import React, { FC, forwardRef, Ref, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Site, Paged, Client } from "../../services/Models";
import { useGetSitesQuery, useLinkClientSiteMutation, useUnlinkClientSiteMutation } from "../../services/Sites";
import LinkButton from "../../components/buttons/LinkButton";
import CollapsibleCard from "../../components/templates/CollapsibleCard";
import { maxPageSize } from "../../const";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import Callout from "../../components/templates/Callout";
import { ButtonGroup, Col, Dropdown, Row } from "react-bootstrap";
import AppButton from "../../components/buttons/AppButton";

interface Props {
    client: Client;
}

const ClientListingCard: FC<Props> = ({
    client,
}) => {

    const {
        isLoading: isSitesLoading,
        isFetching: isSitesFetching,
        isSuccess: isSitesSuccess,
        isError: isSitesError,
        data: sitesData,
        error: sitesError,
    } = useGetSitesQuery({ clientId: client.clientId, size: maxPageSize });

    const {
        isLoading: isAllSitesLoading,
        isFetching: isAllSitesFetching,
        isSuccess: isAllSitesSuccess,
        isError: isAllSitesError,
        data: allSitesData,
        error: allSitesError,
    } = useGetSitesQuery({ size: maxPageSize });

    const {
        data: availableSitesData,
    } = useMemo(() => ({ 
        data: _.differenceBy(allSitesData?.items || [], sitesData?.items || [], "id"),
    }), [ allSitesData, sitesData ]);

    const [
        linkClientSite,
        {
            isError: isClientSiteLinkError,
            error: clientSiteLinkError,
        },
    ] = useLinkClientSiteMutation();

    const [
        unlinkClientSite,
        {
            isError: isClientSiteUnlinkError,
            error: clientSiteUnlinkError,
        },
    ] = useUnlinkClientSiteMutation();

    const [ hasSites, setHasSites ] = useState((sitesData?.items?.length || 0) > 0);

    useEffect(() => {
        setHasSites((sitesData?.items?.length || 0) > 0);
    }, [ sitesData?.items?.length ]);

    type CustomToggleProps = {
        children?: React.ReactNode;
        onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
    };

    // eslint-disable-next-line react/display-name
    const CustomToggle = forwardRef((props: CustomToggleProps, ref: Ref<HTMLAnchorElement>) => (
        <LinkButton
            ref={ref}
            variant="outline-primary"
            size="xs"
            to=""
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            &#x25bc;
        </LinkButton>
    ));


    const renderCardTools = (client: Client) => {
        return (
            <>
                <Dropdown
                    as={ButtonGroup}
                    className="mr-5"
                >
                    <LinkButton
                        key="add-site"
                        variant="outline-primary"
                        icon="plus"
                        size="xs"
                        to={`${client.clientId}/sites/0/edit`}
                    >
                        Add Site
                    </LinkButton>

                    {
                        availableSitesData &&
                        <>
                            <Dropdown.Toggle
                                key="select-site"
                                as={CustomToggle}
                                split
                            />

                            <Dropdown.Menu>
                                {
                                    availableSitesData.map((site) => (
                                        <Dropdown.Item
                                            key={site.siteId}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                linkClientSite({
                                                    clientId: client.clientId,
                                                    siteId: site.siteId,
                                                });
                                            }}
                                        >
                                            {site.name}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </>
                    }
                </Dropdown>

                <LinkButton
                    key="edit-client"
                    variant="tool"
                    icon="pencil-alt"
                    to={`${client.clientId}/edit`}
                />
            </>
        );
    };

    const renderSite = (client: Client, site: Site) => {
        return (
            <Callout
                key={site.siteId}
                variant="primary"
            >
                <Row>
                    <Col sm="10">
                        <LinkButton
                            key="view-site"
                            variant="link"
                            to={`${client.clientId}/sites/${site.siteId}`}
                        >
                            {site.name}
                        </LinkButton>
                    </Col>
                    <Col sm="2" className="text-right">
                        <AppButton
                            variant="outline-primary"
                            icon="times"
                            size="xs"
                            onClick={(e) => {
                                e.preventDefault();
                                unlinkClientSite({
                                    clientId: client.clientId,
                                    siteId: site.siteId,
                                });
                            }}
                        >
                            Remove
                        </AppButton>
                    </Col>
                </Row>
            </Callout>
        );
    };

    const renderSites = (client: Client, sites: Paged<Site> | undefined) => {
        return (
            sites && sites.items &&
            <>
                {sites.items.map((site) => renderSite(client, site))}
            </>
        );
    };

    const renderClientCard = (client: Client) => {
        return (
            <>
                <CollapsibleCard
                    title={client.name}
                    titleLinkTo={`${client.clientId}`}
                    cardTools={renderCardTools(client)}
                    variant="primary"
                    collapsible={hasSites}
                >
                    <SpinnerOverlay
                        isLoading={isSitesLoading || isAllSitesLoading}
                        isFetching={isSitesFetching || isAllSitesFetching}
                        isSuccess={isSitesSuccess && isAllSitesSuccess}
                        isError={isSitesError || isAllSitesError || isClientSiteLinkError || isClientSiteUnlinkError}
                        error={sitesError || allSitesError || clientSiteLinkError || clientSiteUnlinkError}
                    >
                        {renderSites(client, sitesData)}
                    </SpinnerOverlay>
                </CollapsibleCard>
            </>
        );
    };

    return (
        <>
            {renderClientCard(client)}
        </>
    );
};

export default ClientListingCard;