import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { apiPath, xsrfCookieName, xsrfHeaderName } from "../const";

axios.defaults.xsrfCookieName = xsrfCookieName;
axios.defaults.xsrfHeaderName = xsrfHeaderName;

type AxiosBaseQueryArgs = {
    baseUrl: string;
}

type AxiosQueryArgs<D = unknown> = string | AxiosRequestConfig<D>

type AxiosQueryExtraOptions = unknown;

export type AxiosQueryError<T = unknown> = {
    status: number;
    statusText: string;
    data?: T;
};

export const isAxiosQueryError = <T>(error: unknown): error is AxiosQueryError<T> => {
    return (error as AxiosQueryError<T>)?.status !== undefined
        && (error as AxiosQueryError<T>)?.statusText !== undefined;
};

type AxiosQueryMeta<T = unknown, D = unknown> = {
    result: AxiosResponse<T, D> | AxiosError<T>;
};

const axiosBaseQuery =
    (
        { baseUrl, ...baseOptions }: AxiosBaseQueryArgs = { baseUrl: "" }
    ): BaseQueryFn<AxiosQueryArgs, unknown, AxiosQueryError, AxiosQueryExtraOptions, AxiosQueryMeta>  =>
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (args, api, extraOptions) => {

            const {
                baseURL: overriddenBaseUrl = undefined,
                url,
                method = "GET",
                headers = {},
                params = undefined,
                data = undefined,
                ...rest
            } = typeof args === "string" ? { url: args } : args;

            const config = {
                ...baseOptions,
                url: (overriddenBaseUrl ? overriddenBaseUrl : baseUrl) + url,
                method,
                headers,
                params,
                data,
                ...rest,
            } as AxiosRequestConfig;

            try {

                const result = await axios(config);

                console.debug("axiosBaseQuery: result", result);

                if (result.request.responseURL.endsWith("/login")) {
                    window.location = result.request.responseURL;
                }

                const meta = { result: result };

                return {
                    data: result.data,
                    meta,
                };

            } catch (axiosError) {

                const err = axiosError as AxiosError;

                console.debug("axiosBaseQuery: error", err);

                if ([ 401, 403 ].includes(err.response?.status || 0)) {
                    window.location = err.request?.requestURL || "/login";
                }

                const meta = { result: err };

                return {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: err.response?.data,
                    meta,
                };

            }
        };

export const cpwpApi = createApi({
    reducerPath: "cpwpApi",
    baseQuery: axiosBaseQuery({ baseUrl: apiPath }),
    endpoints: () => ({}),
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    tagTypes: [],
});