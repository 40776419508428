import React, { FC } from "react";
import { Variant } from "../PropTypes";

interface Props {
    className?: string;
    variant?: Variant;
}

const Card: FC<Props> = ({
    children,
    className = "",
    variant = "primary",
    ...restProps
}) => {
    return (
        <div
            className={`callout 
                        ${variant ? `callout-${variant}` : ""}  
                        ${className}`}
            {...restProps}
        >
            {children}
        </div>
    );
};

export default Card;