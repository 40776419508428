import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTimeRange, DateTimeRangesEnum } from "../services/Enums";

export interface TestRunListingFilter {
    passed: boolean;
    failed: boolean;
    skipped: boolean;
    ignored: boolean;
    unknown: boolean;
}

export interface TestRunListingSliceState {
    dateTimeRange: DateTimeRangesEnum | DateTimeRange;
    filter: TestRunListingFilter;
}

export interface FilterActionPayload {
    filter: "passed" | "failed" | "skipped" | "ignored" | "unknown";
    visible: boolean;
}

export interface DateTimeRangeActionPayload {
    dateTimeRange: DateTimeRangesEnum | DateTimeRange;
}

const initialState: TestRunListingSliceState = {
    dateTimeRange: DateTimeRangesEnum.oneDay,
    filter: {
        passed: true,
        failed: true,
        skipped: true,
        ignored: true,
        unknown: true,
    },
};

export const testRunListingSlice = createSlice({
    name: "testRunListing",
    initialState: initialState,
    reducers: {
        updateFilter: (state, action: PayloadAction<FilterActionPayload>) => {
            state.filter[action.payload.filter] = action.payload.visible;
        },
        updateDateTimeRange: (state, action: PayloadAction<DateTimeRangeActionPayload>) => {
            state.dateTimeRange = action.payload.dateTimeRange;
        },
    },
});

export const testRunListingUpdateFilter = testRunListingSlice.actions.updateFilter;

export const testRunListingUpdateDateTimeRange = testRunListingSlice.actions.updateDateTimeRange;

export const testRunListingReducer = testRunListingSlice.reducer;