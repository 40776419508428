import React, { FC, ReactNode, useState } from "react";

import Card from "./Card";
import CardHeader from "./CardHeader";
import CardBody from "./CardBody";
import CardTools from "./CardTools";
import LinkButton from "../buttons/LinkButton";
import { Variant } from "../PropTypes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

interface Props {
    className?: string;
    variant: Variant;
    outline?: boolean;
    headerPrependIcon?: IconProp | FontAwesomeIconProps;
    headerAppendIcon?: IconProp | FontAwesomeIconProps;
    title?: string | ReactNode;
    titleLinkTo?: string;
    cardTools?: ReactNode;
    expandIcon?: IconProp | FontAwesomeIconProps;
    collapseIcon?: IconProp | FontAwesomeIconProps;
    initialStateActive?: boolean;
    collapsible?: boolean;
}

const CollapsibleCard: FC<Props> = ({
    children, 
    className = "", 
    variant, 
    outline = true, 
    headerPrependIcon = undefined,
    headerAppendIcon = undefined,
    title = "",
    titleLinkTo,
    cardTools,
    expandIcon = "chevron-down", 
    collapseIcon = "chevron-up", 
    initialStateActive = false,
    collapsible = true,
}) => {

    const [ isActive, setIsActive ] = useState(initialStateActive && collapsible);


    const toggleState = (e: React.MouseEvent | React.KeyboardEvent) => {
        e.preventDefault();
        setIsActive(!isActive);
    };

    return (
        <Card
            className={className}
            variant={variant}
            outline={outline}
        >
            <CardHeader
                prependIcon={headerPrependIcon}
                appendIcon={headerAppendIcon}
                title={title}
                linkTo={titleLinkTo}
            >
                <CardTools key="card-tools">
                    {cardTools}
                    {
                        collapsible &&
                        <LinkButton
                            variant="tool"
                            icon={isActive ? collapseIcon : expandIcon}
                            onClick={toggleState}
                            to="#"
                        />
                    }
                </CardTools>
            </CardHeader>
            <div className={`panel-collapse in collapse ${isActive && collapsible ? "show" : ""}`}>
                <CardBody>
                    {children}
                </CardBody>
            </div>
        </Card>
    );
};

export default CollapsibleCard;