import React, { FC } from "react";
import { Size, Variant } from "../PropTypes";

interface Props {
    className?: string;
    variant?: Variant;
    size?: Size;
}

const Badge: FC<Props> = ({
    children, 
    className = "", 
    variant = "primary", 
    size = "md",
    ...restProps
}) => {
    return (
        <span
            {...restProps}
            className={`badge 
                       ${variant ? `badge-${variant}` : ""}
                       ${size ? `badge-${size}` : ""}
                       ${className}`}
        >
            { children }
        </span>
    );
};

export default Badge;