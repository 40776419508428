const contextPath = "/";

const apiPath = "/api";

const xsrfCookieName = "XSRF-TOKEN";
const xsrfParameterName = "_csrf";
const xsrfHeaderName = "X-XSRF-TOKEN";

const maxPageSize = 10000;
const defaultPageSize = 20;

export {
    contextPath,
    apiPath,
    xsrfCookieName,
    xsrfParameterName,
    xsrfHeaderName,
    maxPageSize,
    defaultPageSize,
};