import { cpwpApi } from "./CpwpApi";
import { Paged, Probe, TransformPagedResponseArg } from "./Models";

interface GetProbeQueryArg {
    probeId: string;
}

interface SaveProbeQueryArg {
    siteId?: string;
    probeId: string;
    probe: Partial<Probe>;
}

interface GetProbesQueryArg {
    siteId?: string;
    page?: number;
    size?: number;
    compact?: boolean;
}

interface EmbeddedProbesPage {
    probes: Probe[];
}

interface LinkSiteProbeQueryArg {
    siteId: string;
    probeId: string;
}

interface UnlinkSiteProbeQueryArg {
    siteId: string;
    probeId: string;
}

const probesApi =
    cpwpApi
        .enhanceEndpoints({
            addTagTypes: [ "Probe" ],
        })
        .injectEndpoints({
            endpoints: (builder) => ({
                saveProbe: builder.mutation<Probe, SaveProbeQueryArg>({
                    query:
                        ({ siteId, probeId, probe }) => ({
                            url: siteId ? `/sites/${siteId}/probes/${probeId}` : `/probes/${probeId}`,
                            method: "PUT",
                            data: probe,
                        }),
                    invalidatesTags:
                        () => [ { type: "Probe" } ],
                }),
                getProbe: builder.query<Probe, GetProbeQueryArg>({
                    query:
                        ({ probeId }) =>
                            `/probes/${probeId}`,
                    providesTags:
                        (result, error, { probeId }) =>
                            [ { type: "Probe", probeId } ],
                }),
                getProbes: builder.query<Paged<Probe>, GetProbesQueryArg>({
                    query:
                        ({ siteId, page = 0, size = 20, compact = true  }) => ({
                            url: siteId ? `/sites/${siteId}/probes` : `/probes`,
                            params: {
                                page: page,
                                size: size,
                                compact: compact,
                            },
                        }),
                    transformResponse:
                        (result: TransformPagedResponseArg<EmbeddedProbesPage>): Paged<Probe> => ({
                            items: result?._embedded?.probes || [],
                            page: result?.page || { size: 20, totalElements: 0, totalPages: 0, number: 0 },
                        }),
                    providesTags:
                        (result, error, { siteId, page, size, compact }) =>
                            [ { type: "Probe", siteId, page, size, compact } ],
                }),
                unlinkSiteProbe: builder.mutation<unknown, UnlinkSiteProbeQueryArg>({
                    query:
                        ({ siteId, probeId }) => ({
                            url: `/sites/${siteId}/probes/${probeId}`,
                            method: "DELETE",
                        }),
                    invalidatesTags:
                        () => [ { type: "Probe" } ],
                }),
                linkSiteProbe: builder.mutation<Probe, LinkSiteProbeQueryArg>({
                    query:
                        ({ siteId, probeId }) => ({
                            url: `/sites/${siteId}/probes/${probeId}`,
                            method: "PUT",
                        }),
                    invalidatesTags:
                        () => [ { type: "Probe" } ],
                }),
            }),
        });

export const {
    useSaveProbeMutation,
    useGetProbeQuery,
    useGetProbesQuery,
    useLinkSiteProbeMutation,
    useUnlinkSiteProbeMutation,
} = probesApi;

