import { cpwpApi } from "./CpwpApi";
import { Paged, Site, TransformPagedResponseArg } from "./Models";

interface GetSiteQueryArg {
    siteId: string;
}

interface SaveSiteQueryArg {
    clientId?: string;
    siteId: string;
    site: Partial<Site>;
}

interface GetSitesQueryArg {
    clientId?: string;
    page?: number;
    size?: number;
    compact?: boolean;
}

interface EmbeddedSitesPage {
    sites: Site[];
}

interface LinkClientSiteQueryArg {
    clientId: string;
    siteId: string;
}

interface UnlinkClientSiteQueryArg {
    clientId: string;
    siteId: string;
}

const sitesApi =
    cpwpApi
        .enhanceEndpoints({
            addTagTypes: [ "Site" ],
        })
        .injectEndpoints({
            endpoints: (builder) => ({
                saveSite: builder.mutation<Site, SaveSiteQueryArg>({
                    query:
                        ({ clientId, siteId, site }) => ({
                            url: clientId ? `/clients/${clientId}/sites/${siteId}` : `/sites/${siteId}`,
                            method: "PUT",
                            data: site,
                        }),
                    invalidatesTags:
                        () => [ { type: "Site" } ],
                }),
                getSite: builder.query<Site, GetSiteQueryArg>({
                    query:
                        ({ siteId }) =>
                            `/sites/${siteId}`,
                    providesTags:
                        (result, error, { siteId }) =>
                            [ { type: "Site", siteId } ],
                }),
                getSites: builder.query<Paged<Site>, GetSitesQueryArg>({
                    query:
                        ({ clientId, page = 0, size = 20, compact = true  }) => ({
                            url: clientId ? `/clients/${clientId}/sites` : `/sites`,
                            params: {
                                page: page,
                                size: size,
                                compact: compact,
                            },
                        }),
                    transformResponse:
                        (result: TransformPagedResponseArg<EmbeddedSitesPage>): Paged<Site> => ({
                            items: result?._embedded?.sites || [],
                            page: result?.page || { size: 20, totalElements: 0, totalPages: 0, number: 0 },
                        }),
                    providesTags:
                        (result, error, { clientId, page, size, compact }) =>
                            [ { type: "Site", clientId, page, size, compact } ],
                }),
                unlinkClientSite: builder.mutation<unknown, UnlinkClientSiteQueryArg>({
                    query:
                        ({ clientId, siteId }) => ({
                            url: `/clients/${clientId}/sites/${siteId}`,
                            method: "DELETE",
                        }),
                    invalidatesTags:
                        () => [ { type: "Site" } ],
                }),
                linkClientSite: builder.mutation<Site, LinkClientSiteQueryArg>({
                    query:
                        ({ clientId, siteId }) => ({
                            url: `/clients/${clientId}/sites/${siteId}`,
                            method: "PUT",
                        }),
                    invalidatesTags:
                        () => [ { type: "Site" } ],
                }),
            }),
        });

export const {
    useSaveSiteMutation,
    useGetSiteQuery,
    useGetSitesQuery,
    useLinkClientSiteMutation,
    useUnlinkClientSiteMutation,
} = sitesApi;

