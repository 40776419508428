import React, { FC, useMemo } from "react";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../../components/templates/ContentHeader";
import ContentBody from "../../components/templates/ContentBody";

import LinkButton from "../../components/buttons/LinkButton";
import ContextDashboard from "../../templates/dashboard/ContextDashboard";
import { useGetClientsQuery } from "../../services/Clients";
import { Client } from "../../services/Models";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import ClientListingCard from "./ClientListingCard";
import { maxPageSize } from "../../const";
import { useParams } from "react-router-dom";

const ClientListing: FC = () => {

    const { tenantId } = useParams();

    const { isLoading, isFetching, isSuccess, isError,
            data, error } = useGetClientsQuery({ tenantId, size: maxPageSize });

    const clients = useMemo(() => data?.items || [], [ data ]);

    const renderClientCard = (client: Client) => {
        return (
            <ClientListingCard key={client.clientId} client={client} />
        );
    };

    const renderClients = (clients: Client[]) => {
        return (
            <Row>
                <Col sm="12">
                    { clients.map(renderClientCard) }
                </Col>
            </Row>
        );
    };

    return (
        <>
            <ContextDashboard>
                <ContentHeader>
                    <Row>
                        <Col sm="6">
                            <h1 className="float-left mr-5">Clients</h1>
                            <LinkButton
                                variant="outline-primary"
                                icon="plus"
                                to="0/edit"
                            >
                                Add Client
                            </LinkButton>
                        </Col>
                        <Col sm="6">
                        </Col>
                    </Row>
                </ContentHeader>
                <ContentBody>
                    <SpinnerOverlay
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isSuccess={isSuccess}
                        isError={isError}
                        error={error}
                    >
                        { renderClients(clients) }
                    </SpinnerOverlay>
                </ContentBody>
            </ContextDashboard>
        </>
    );
};

export default ClientListing;