import React, { FC } from "react";
import { ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";

interface Props {
    name: string;
    fieldColSize?: number;
    messageColSize?: number;
}

const ValidationFieldGroup: FC<Props> = ({
    children, 
    name, 
    fieldColSize = 8, 
    messageColSize = 4, 
    ...restProps
}) => {
    return (
        <Row>
            <Col sm={fieldColSize}>
                {children}
            </Col>
            <Col sm={messageColSize}>
                <ErrorMessage name={name} {...restProps}>
                    {message => <span className="text-danger">{message}</span>}
                </ErrorMessage>
            </Col>
        </Row>
    );
};

export default ValidationFieldGroup;