import { cpwpApi } from "./CpwpApi";
import { Paged, Client, TransformPagedResponseArg } from "./Models";

interface GetClientQueryArg {
    clientId: string;
}

interface SaveClientQueryArg {
    tenantId?: string;
    clientId: string;
    client: Partial<Client>;
}

interface GetClientsQueryArg {
    tenantId?: string;
    page?: number;
    size?: number;
    compact?: boolean;
}

interface EmbeddedClientsPage {
    clients: Client[];
}

interface LinkTenantClientQueryArg {
    tenantId: string;
    clientId: string;
}

interface UnlinkTenantClientQueryArg {
    tenantId: string;
    clientId: string;
}

const clientsApi =
    cpwpApi
        .enhanceEndpoints({
            addTagTypes: [ "Client" ],
        })
        .injectEndpoints({
            endpoints: (builder) => ({
                saveClient: builder.mutation<Client, SaveClientQueryArg>({
                    query:
                        ({ tenantId, clientId, client }) => ({
                            url: tenantId ? `/tenants/${tenantId}/clients/${clientId}` : `/clients/${clientId}`,
                            method: "PUT",
                            data: client,
                        }),
                    invalidatesTags:
                        () => [ { type: "Client" } ],
                }),
                getClient: builder.query<Client, GetClientQueryArg>({
                    query:
                        ({ clientId }) =>
                            `/clients/${clientId}`,
                    providesTags:
                        (result, error, { clientId }) =>
                            [ { type: "Client", clientId } ],
                }),
                getClients: builder.query<Paged<Client>, GetClientsQueryArg>({
                    query:
                        ({ tenantId, page = 0, size = 20, compact = true  }) => ({
                            url: tenantId ? `/tenants/${tenantId}/clients` : `/clients`,
                            params: {
                                page: page,
                                size: size,
                                compact: compact,
                            },
                        }),
                    transformResponse:
                        (result: TransformPagedResponseArg<EmbeddedClientsPage>): Paged<Client> => ({
                            items: result?._embedded?.clients || [],
                            page: result?.page || { size: 20, totalElements: 0, totalPages: 0, number: 0 },
                        }),
                    providesTags:
                        (result, error, { tenantId, page, size, compact }) =>
                            [ { type: "Client", tenantId, page, size, compact } ],
                }),
                unlinkTenantClient: builder.mutation<unknown, UnlinkTenantClientQueryArg>({
                    query:
                        ({ tenantId, clientId }) => ({
                            url: `/tenants/${tenantId}/clients/${clientId}`,
                            method: "DELETE",
                        }),
                    invalidatesTags:
                        () => [ { type: "Client" } ],
                }),
                linkTenantClient: builder.mutation<Client, LinkTenantClientQueryArg>({
                    query:
                        ({ tenantId, clientId }) => ({
                            url: `/tenants/${tenantId}/clients/${clientId}`,
                            method: "PUT",
                        }),
                    invalidatesTags:
                        () => [ { type: "Client" } ],
                }),
            }),
        });

export const {
    useSaveClientMutation,
    useGetClientQuery,
    useGetClientsQuery,
    useLinkTenantClientMutation,
    useUnlinkTenantClientMutation,
} = clientsApi;

