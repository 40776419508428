import moment, { duration, DurationInputArg1, DurationInputArg2, Moment, MomentInput } from "moment";
import { enumKeys, snap } from "./Utility";

export interface DateTimeRange {
    start: MomentInput;
    end: MomentInput;
}

export const isDateTimeRange = (dateTimeRange: unknown): dateTimeRange is DateTimeRange => {
    return (dateTimeRange as DateTimeRange).start !== undefined
        && (dateTimeRange as DateTimeRange).end !== undefined;
};

export class DateTimeRangeProvider {
    readonly label: string;
    readonly shortLabel: string;
    private readonly inp: DurationInputArg1 | undefined;
    private readonly unit: DurationInputArg2 | undefined;

    constructor(label: string, shortLabel: string, inp?: DurationInputArg1, unit?: DurationInputArg2) {
        this.label = label;
        this.shortLabel = shortLabel;
        this.inp = inp;
        this.unit = unit;
    }

    snapToLower(date: MomentInput): Moment {
        return snap(date, { inp: 5, unit: "minutes" }, "floor");
    }

    snapToUpper(date: MomentInput): Moment {
        return snap(date, { inp: 5, unit: "minutes" }, "ceil");
    }

    range(): DateTimeRange {
        return {
            start: this.snapToLower((+moment()) - (+duration(this.inp, this.unit))),
            end: this.snapToUpper(+moment()),
        };
    }
}

interface DateTimeRangesProviderInterface {
    oneHour: DateTimeRangeProvider;
    threeHours: DateTimeRangeProvider;
    sixHours: DateTimeRangeProvider;
    twelveHours: DateTimeRangeProvider;
    oneDay: DateTimeRangeProvider;
    twoDays: DateTimeRangeProvider;
    sevenDays: DateTimeRangeProvider;
    fourteenDays: DateTimeRangeProvider;
    thirtyDays: DateTimeRangeProvider;
    [key: DateTimeRangesEnum | string]: DateTimeRangeProvider;
}

export const dateTimeRangesProvider: DateTimeRangesProviderInterface = {
    oneHour:
        new DateTimeRangeProvider("Last 1 hour", "1 H", 1, "hour"),
    threeHours:
        new DateTimeRangeProvider("Last 3 hours", "3 H", 3, "hours"),
    sixHours:
        new DateTimeRangeProvider("Last 6 hours", "6 H", 6, "hours"),
    twelveHours:
        new DateTimeRangeProvider("Last 12 hours", "12 H", 12, "hours"),
    oneDay:
        new DateTimeRangeProvider("Last day", "24 H", 1, "day"),
    twoDays:
        new DateTimeRangeProvider("Last 2 days", "2 D", 2, "days"),
    sevenDays:
        new DateTimeRangeProvider("Last 7 days", "7 D", 7, "days"),
    fourteenDays:
        new DateTimeRangeProvider("Last 14 days", "14 D", 14, "days"),
    thirtyDays:
        new DateTimeRangeProvider("Last 30 days", "30 D", 30, "days"),
};

export enum DateTimeRangesEnum {
    oneHour = "oneHour",
    threeHours = "threeHours",
    sixHours = "sixHours",
    twelveHours = "twelveHours",
    oneDay = "oneDay",
    twoDays = "twoDays",
    sevenDays = "sevenDays",
    fourteenDays = "fourteenDays",
    thirtyDays = "thirtyDays",
}

export const dateTimeRangesEnumKeys: DateTimeRangesEnum[] = enumKeys(DateTimeRangesEnum);
