import React, { ChangeEventHandler, FC, HTMLInputTypeAttribute } from "react";
import { Field } from "formik";
import _ from "lodash";

interface Props {
    name: string;
    type?: HTMLInputTypeAttribute | string;
    className?: string;
    errors?: unknown;
    touched?: unknown;
    readOnly?: boolean;
    onChange?: ChangeEventHandler;
    placeholder?: string;
}

const InputField: FC<Props> = ({
    name, 
    type = "text", 
    className = "",
    errors = {}, 
    touched = {},
    readOnly = false,
    onChange,
    placeholder,
    ...restProps
}) => {
    return (
        <Field component="input"
               name={name} type={type}
               className={`form-control 
                           ${className} 
                           ${_.get(errors, name, false) && _.get(touched, name, false) ? "is-invalid" : ""}`}
               readOnly={readOnly}
               onChange={onChange}
               placeholder={placeholder}
               {...restProps}
        />
    );
};

export default InputField;