import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./store";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { contextPath } from "./const";

import App from "./App";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router basename={contextPath}>
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("react"),
);
