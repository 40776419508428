import React, { ChangeEvent, ChangeEventHandler, FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../../components/templates/ContentHeader";
import ContentBody from "../../components/templates/ContentBody";
import Card from "../../components/templates/Card";
import CardHeader from "../../components/templates/CardHeader";
import CardBody from "../../components/templates/CardBody";
import LabelFieldGroup from "../../components/fields/LabelFieldGroup";
import IconFieldGroup from "../../components/fields/IconFieldGroup";
import { ddToDMS, dmsToDD } from "../../services/Utility";
import AppButton from "../../components/buttons/AppButton";
import CardFooter from "../../components/templates/CardFooter";
import InputField from "../../components/fields/InputField";
import ValidationFieldGroup from "../../components/fields/ValidationFieldGroup";
import LinkButton from "../../components/buttons/LinkButton";
import { useGetProbeQuery, useSaveProbeMutation } from "../../services/Probes";
import { Probe } from "../../services/Models";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import { setFormValueCallback } from "../../components/PropTypes";

const ProbeForm: FC = ({
   ...restProps
}) => {

    let { probeId } = useParams();
    probeId = probeId ? probeId : "0";

    const navigate = useNavigate();

    const [ formValues, setFormValues ] =
        useState({
            id: "",
            probeId: "",
            serialNumber: "",
            model: "",
            name: "",
            dns: "",
            vpnIpV4: "",
            vpnIpV6: "",
            location: {
                name: "",
                address: {
                    line1: "",
                    line2: "",
                    line3: "",
                    line4: "",

                    locality: "",
                    region: "",
                    country: "",

                    code: "",
                },
                geoPoint: {
                    lat: 0.0,
                    lon: 0.0,
                },
                geoPointDisplay: {
                    latD: 0,
                    latM: 0,
                    latS: 0,
                    lonD: 0,
                    lonM: 0,
                    lonS: 0,
                },
            },
        });

     const validationSchema =
         Yup.object().shape({
             probeId: Yup.string().required("Required"),
             serialNumber: Yup.string().required("Required"),
             model: Yup.string().required("Required"),
             name: Yup.string().required("Required"),
             location: Yup.object({
                 geoPoint: Yup.object({
                     lat: Yup.number().min(-90, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033").max(90, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033"),
                     lon: Yup.number().min(-180, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033").max(180, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033"),
                 }),
                 geoPointDisplay: Yup.object({
                     latD: Yup.number().min(-90, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033").max(90, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033"),
                     latM: Yup.number().min(0, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033").max(59, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033"),
                     latS: Yup.number().min(0, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033").max(59, "-90\u00B00\u20320\u2033 to 90\u00B00\u20320\u2033"),
                     lonD: Yup.number().min(-180, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033").max(180, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033"),
                     lonM: Yup.number().min(0, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033").max(59, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033"),
                     lonS: Yup.number().min(0, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033").max(59, "-180\u00B00\u20320\u2033 to 180\u00B00\u20320\u2033"),
                 }),
             }),
         });

    const {
        isLoading: isLoading,
        isFetching: isFetching,
        isSuccess: isSuccessGet,
        isError: isErrorGet,
        data: probe,
        error: errorGet,
    } = useGetProbeQuery({ probeId });

    const [
        saveProbe,
        {
            isLoading: isUpdating,
            isSuccess: isSuccessSave,
            isError: isErrorSave,
            error: errorSave,
        },
    ] = useSaveProbeMutation();

    const onSubmit =
        (values: Probe) => {
            saveProbe({
                probeId: (probeId ? probeId : values.probeId), 
                probe: values, 
            });
        };
    
    useEffect(() => {
        if (isSuccessSave) navigate("..", { replace: true });
    }, [ isSuccessSave, navigate ]);

    const setFormValue = (e: ChangeEvent<HTMLInputElement>, field: string, value: unknown, handleChange: ChangeEventHandler, setFieldValue: setFormValueCallback) => {
        handleChange(e);
        setFieldValue(e.target.name, e.target.value);
        setFieldValue(field, value);
    };

    useEffect(() => {
        if (!probeId || probeId === "0") return;

        if (isSuccessGet && probe) {
            const retrievedFormValues = _.cloneDeep(probe);

            const [ latD, latM, latS ] = ddToDMS(probe.location.geoPoint.lat);
            const [ lonD, lonM, lonS ] = ddToDMS(probe.location.geoPoint.lon);

            setFormValues({
                ...retrievedFormValues,
                location: {
                    ...retrievedFormValues.location,
                    geoPointDisplay: {
                        ...retrievedFormValues.location.geoPointDisplay,
                        latD: latD,
                        latM: latM,
                        latS: latS,
                        lonD: lonD,
                        lonM: lonM,
                        lonS: lonS,
                    },
                },
            });
        }
    }, [ isSuccessGet, probe, probeId ]);

    return (
        <>
            <ContentHeader>
                <Row>
                    <Col sm="6">
                        <h1 className="float-left mr-5">Probe</h1>
                        <LinkButton
                            variant="outline-primary"
                            icon="chevron-up"
                            to="../probes"
                        >
                            Probes
                        </LinkButton>
                    </Col>
                    <Col sm="6">
                    </Col>
                </Row>
            </ContentHeader>
            <ContentBody>
                <SpinnerOverlay
                    isLoading={isLoading || isUpdating}
                    isFetching={isFetching || isUpdating}
                    isSuccess={isSuccessGet || isSuccessSave}
                    isError={((probeId && probeId !== "0") && isErrorGet) || isErrorSave}
                    error={((probeId && probeId !== "0") && errorGet) || errorSave}
                >
                <Formik initialValues={formValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                >
                    {({ handleChange, setFieldValue, values, errors, touched }) => (
                    <Form className="form-horizontal">
                        <Row>
                            <Col md="6">
                                <Card>
                                    <CardHeader title="Identification" prependIcon="fingerprint" />
                                    <CardBody>
                                        <LabelFieldGroup label="Identifier">
                                            <ValidationFieldGroup name="probeId">
                                                <InputField name="id" type="hidden" errors={errors} touched={touched} />
                                                <IconFieldGroup prependIcon="fingerprint">
                                                    <InputField
                                                        name="probeId" errors={errors} touched={touched}
                                                        readOnly={(probeId && probeId !== "0") as boolean}
                                                        onChange={
                                                            (e: ChangeEvent<HTMLInputElement>) =>
                                                                setFormValue(e, "id", e.target.value, handleChange, setFieldValue)
                                                        }
                                                    />
                                                </IconFieldGroup>
                                            </ValidationFieldGroup>
                                        </LabelFieldGroup>
                                        <LabelFieldGroup label="Name">
                                            <ValidationFieldGroup name="name">
                                                <IconFieldGroup prependIcon="satellite">
                                                    <InputField name="name" errors={errors} touched={touched} onChange={handleChange} />
                                                </IconFieldGroup>
                                            </ValidationFieldGroup>
                                        </LabelFieldGroup>
                                        <LabelFieldGroup label="Model">
                                            <ValidationFieldGroup name="model">
                                                <IconFieldGroup prependIcon="cubes">
                                                    <InputField name="model" errors={errors} touched={touched} onChange={handleChange} />
                                                </IconFieldGroup>
                                            </ValidationFieldGroup>
                                        </LabelFieldGroup>
                                        <LabelFieldGroup label="Serial Nr.">
                                            <ValidationFieldGroup name="serialNumber">
                                                <IconFieldGroup prependIcon="barcode">
                                                    <InputField name="serialNumber" errors={errors} touched={touched} onChange={handleChange} />
                                                </IconFieldGroup>
                                            </ValidationFieldGroup>
                                        </LabelFieldGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardHeader title="Networking" prependIcon="network-wired" />
                                    <CardBody>
                                        <LabelFieldGroup label="DNS Name">
                                            <IconFieldGroup prependIcon="laptop">
                                                <InputField name="dns" errors={errors} touched={touched} onChange={handleChange}
                                                            data-inputmask={"'regex': '^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{0,1}(?:\\\\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{0,1})*$'"}
                                                />
                                            </IconFieldGroup>
                                            {/*^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{0,1}(?:\\\\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{0,1})*$*/}
                                            {/*[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\\\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)**/}
                                            {/*^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)(?:\\\\.(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$*/}
                                        </LabelFieldGroup>
                                        <LabelFieldGroup label="VPN IP (IPv4)">
                                            <IconFieldGroup prependIcon="laptop">
                                                <InputField name="vpnIpV4" errors={errors} touched={touched} onChange={handleChange}
                                                            data-inputmask={"\"mask\": [\"9{1,3}.9{1,3}.9{1,3}.9{1,3}\"], \"keepStatic\": true"}
                                                />
                                            </IconFieldGroup>
                                        </LabelFieldGroup>
                                        <LabelFieldGroup label="VPN IP (IPv6)">
                                            <IconFieldGroup prependIcon="laptop">
                                                <InputField name="vpnIpV6" errors={errors} touched={touched} onChange={handleChange}
                                                            data-inputmask={"\"mask\": [\"#{4}:#{4}:#{4}:#{4}:#{4}:#{4}:#{4}:#{4}\"], \"keepStatic\": true"}
                                                />
                                            </IconFieldGroup>
                                        </LabelFieldGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Card>
                                    <CardHeader title="Location" prependIcon="globe-africa" />
                                    <CardBody>
                                        <LabelFieldGroup label="Name">
                                            <InputField name="location.name" errors={errors} touched={touched} onChange={handleChange} />
                                        </LabelFieldGroup>
                                        <LabelFieldGroup label="Address">
                                            <InputField name="location.address.line1" errors={errors} touched={touched} onChange={handleChange} className="mb-1" placeholder="Line 1" />
                                            <InputField name="location.address.line2" errors={errors} touched={touched} onChange={handleChange} className="mb-1" placeholder="Line 2" />
                                            <InputField name="location.address.line3" errors={errors} touched={touched} onChange={handleChange} className="mb-1" placeholder="Line 3" />
                                            <InputField name="location.address.line4" errors={errors} touched={touched} onChange={handleChange} className="mb-3" placeholder="Line 4" />

                                            <InputField name="location.address.locality" errors={errors} touched={touched} onChange={handleChange} className="mb-1" placeholder="City / Town" />
                                            <InputField name="location.address.region" errors={errors} touched={touched} onChange={handleChange} className="mb-1" placeholder="Province" />
                                            <InputField name="location.address.country" errors={errors} touched={touched} onChange={handleChange} className="mb-3" placeholder="Country" />

                                            <InputField name="location.address.code" errors={errors} touched={touched} onChange={handleChange} placeholder="Code" />
                                        </LabelFieldGroup>
                                        <LabelFieldGroup label="Geo Location">
                                            <InputField name="location.geoPoint.lat" type="hidden" errors={errors} touched={touched} />
                                            <InputField name="location.geoPoint.lon" type="hidden" errors={errors} touched={touched} />
                                            <LabelFieldGroup label="Latitude">
                                                <Row className="mb-1">
                                                    <Col sm="3">
                                                        <IconFieldGroup appendText={"\u00B0"}>
                                                            <InputField
                                                                name="location.geoPointDisplay.latD" errors={errors} touched={touched}
                                                                onChange={
                                                                    (e: ChangeEvent<HTMLInputElement>) =>
                                                                        setFormValue(
                                                                            e,
                                                                            "location.geoPoint.lat",
                                                                            dmsToDD(e.target.value, values.location.geoPointDisplay?.latM, values.location.geoPointDisplay?.latS),
                                                                            handleChange,
                                                                            setFieldValue,
                                                                        )
                                                                }
                                                            />
                                                        </IconFieldGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <IconFieldGroup appendText={"\u2032"}>
                                                            <InputField
                                                                name="location.geoPointDisplay.latM" errors={errors} touched={touched}
                                                                onChange={
                                                                    (e: ChangeEvent<HTMLInputElement>) =>
                                                                        setFormValue(
                                                                            e,
                                                                            "location.geoPoint.lat",
                                                                            dmsToDD(values.location.geoPointDisplay?.latD, e.target.value, values.location.geoPointDisplay?.latS),
                                                                            handleChange,
                                                                            setFieldValue,
                                                                        )
                                                                }
                                                            />
                                                        </IconFieldGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <IconFieldGroup appendText={"\u2033"}>
                                                            <InputField
                                                                name="location.geoPointDisplay.latS" errors={errors} touched={touched}
                                                                onChange={
                                                                    (e: ChangeEvent<HTMLInputElement>) =>
                                                                        setFormValue(
                                                                            e,
                                                                            "location.geoPoint.lat",
                                                                            dmsToDD(values.location.geoPointDisplay?.latD, values.location.geoPointDisplay?.latM, e.target.value),
                                                                            handleChange,
                                                                            setFieldValue
                                                                        )
                                                                }
                                                            />
                                                        </IconFieldGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <ErrorMessage name="location.geoPointDisplay.latD" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                        <ErrorMessage name="location.geoPointDisplay.latM" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                        <ErrorMessage name="location.geoPointDisplay.latS" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                        <ErrorMessage name="location.geoPoint.lat" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                    </Col>
                                                </Row>
                                            </LabelFieldGroup>
                                            <LabelFieldGroup label="Longitude">
                                                <Row className="mb-1">
                                                    <Col sm="3">
                                                        <IconFieldGroup appendText={"\u00B0"}>
                                                            <InputField
                                                                name="location.geoPointDisplay.lonD" errors={errors} touched={touched}
                                                                onChange={
                                                                    (e: ChangeEvent<HTMLInputElement>) =>
                                                                        setFormValue(
                                                                            e,
                                                                            "location.geoPoint.lon",
                                                                            dmsToDD(e.target.value, values.location.geoPointDisplay?.lonM, values.location.geoPointDisplay?.lonS),
                                                                            handleChange,
                                                                            setFieldValue,
                                                                        )
                                                                }
                                                            />
                                                        </IconFieldGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <IconFieldGroup appendText={"\u2032"}>
                                                            <InputField
                                                                name="location.geoPointDisplay.lonM" errors={errors} touched={touched}
                                                                onChange={
                                                                    (e: ChangeEvent<HTMLInputElement>) =>
                                                                        setFormValue(
                                                                            e,
                                                                            "location.geoPoint.lon",
                                                                            dmsToDD(values.location.geoPointDisplay?.lonD, e.target.value, values.location.geoPointDisplay?.lonS),
                                                                            handleChange,
                                                                            setFieldValue,
                                                                        )
                                                                }
                                                            />
                                                        </IconFieldGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <IconFieldGroup appendText={"\u2033"}>
                                                            <InputField
                                                                name="location.geoPointDisplay.lonS" errors={errors} touched={touched}
                                                                onChange={
                                                                    (e: ChangeEvent<HTMLInputElement>) =>
                                                                        setFormValue(
                                                                            e,
                                                                            "location.geoPoint.lon",
                                                                            dmsToDD(values.location.geoPointDisplay?.lonD, values.location.geoPointDisplay?.lonM, e.target.value),
                                                                            handleChange,
                                                                            setFieldValue
                                                                        )
                                                                }
                                                            />
                                                        </IconFieldGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <ErrorMessage name="location.geoPointDisplay.lonD" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                        <ErrorMessage name="location.geoPointDisplay.lonM" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                        <ErrorMessage name="location.geoPointDisplay.lonS" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                        <ErrorMessage name="location.geoPoint.lon" {...restProps}>
                                                            {message => <span className="text-danger">{message}</span>}
                                                        </ErrorMessage>
                                                    </Col>
                                                </Row>
                                            </LabelFieldGroup>
                                        </LabelFieldGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardHeader title="Support" prependIcon="user-astronaut" />
                                    <CardBody>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardFooter className="text-center">
                                        <AppButton
                                            variant="primary"
                                            type="submit"
                                            className="mr-3"
                                        >
                                            Save
                                        </AppButton>
                                        <LinkButton
                                            variant="outline-primary"
                                            to="../probes"
                                            className="mr-3"
                                        >
                                            Cancel
                                        </LinkButton>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                    )}
                </Formik>
                </SpinnerOverlay>
            </ContentBody>
        </>
    );
};

export default ProbeForm;