import React, { FC } from "react";
import { Link } from "react-router-dom";

const Branding: FC = () => {

    return (
        <aside className="sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link">
                <img
                    src="/dist/images/logo.png"
                    alt="Southern Cross Solutions"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: ".8" }}
                />
                <span className="brand-text font-weight-light">CPWP Control</span>
            </Link>
        </aside>
    );

};


export default Branding;
