import React, { FC, useMemo } from "react";
import Hexagon from "react-hexagon";
import { Probe, TestRunState } from "../../services/Models";
import { useNavigate } from "react-router-dom";
import { useGetTestRunsLatestQuery } from "../../services/TestRuns";
import moment from "moment";

interface Props {
    probe: Probe;
}

const ProbeHexCell: FC<Props> = ({
    probe,
}) => {
    const navigate = useNavigate();

    const { data } = useGetTestRunsLatestQuery({ probeId: probe.probeId });

    const testRun = useMemo(() => data?.items[0], [ data ]);

    function renderFillColor(testRun: TestRunState | undefined): string {
        if (!testRun) return "#unknownGradient";
        if (testRun.successful) return "#successGradient";
        if (testRun.failureCount) return "#dangerGradient";
        if (testRun.assumptionFailureCount) return "#warningGradient";

        return "#unknownGradient";
    }

    function renderStrokeColor(testRun: TestRunState | undefined): string {
        if (!testRun) return "#505050";
        if (testRun.successful) return "#28a745";
        if (testRun.failureCount) return "#dc3545";
        if (testRun.assumptionFailureCount) return "#ffc107";

        return "#505050";
    }

    return (
        <div className="hexagon-grid-col">
            <Hexagon
                key={probe.probeId}
                onClick={() => {
                    navigate(`probes/${probe.probeId}`, { replace: true });
                }}
                style={{
                    stroke: renderStrokeColor(testRun),
                    fill: `url(${renderFillColor(testRun)})`,
                }}
            >
                <text x="50%" y="40%">
                    { probe.name }
                </text>
                <text x="50%" y="60%" className="smaller">
                    {
                        testRun && moment(testRun.scheduled).format("YYYY-MM-DD HH:mm")
                    }
                </text>
                <defs>
                    <linearGradient id="successGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#48b461"/>
                        <stop offset="100%" stopColor="#28a745"/>
                    </linearGradient>
                    <linearGradient id="dangerGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#e15361"/>
                        <stop offset="100%" stopColor="#dc3545"/>
                    </linearGradient>
                    <linearGradient id="warningGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#ffca2c"/>
                        <stop offset="100%" stopColor="#ffc107"/>
                    </linearGradient>
                    <linearGradient id="unknownGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#dbdbdb"/>
                        <stop offset="100%" stopColor="#505050"/>
                    </linearGradient>
                </defs>
            </Hexagon>
        </div>
    );
};

export default ProbeHexCell;