import React, { FC, ReactNode } from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { isFontAwesomeIconProps } from "../PropTypes";

interface Props {
    className?: string;
    prependIcon?: IconProp | FontAwesomeIconProps;
    appendIcon?: IconProp | FontAwesomeIconProps;
    title?: string | ReactNode;
    linkTo?: string;
}

const CardHeader: FC<Props> = ({
    children, 
    className = "", 
    prependIcon, 
    appendIcon, 
    title = "", 
    linkTo = "", 
    ...restProps
}) => {

    const titleNodes: ReactNode[] = [];

    if (prependIcon) {
        const prependIconProps: FontAwesomeIconProps
            = isFontAwesomeIconProps(prependIcon)
            ? prependIcon
            : { icon: prependIcon, size: "sm" };

        titleNodes.push(<FontAwesomeIcon key="prepend" fixedWidth className="mr-1" {...prependIconProps} />);
    }

    titleNodes.push(title);

    if (appendIcon) {
        const appendIconProps: FontAwesomeIconProps
            = isFontAwesomeIconProps(appendIcon)
            ? appendIcon
            : { icon: appendIcon, size: "sm" };

        titleNodes.push(<FontAwesomeIcon key="append" fixedWidth className="ml-1" {...appendIconProps} />);
    }

    const titleTemplate: ReactNode
        = linkTo
        ? <Link to={linkTo}>{titleNodes}</Link>
        : <>{titleNodes}</>;

    return (
        <div className={`card-header ${className}`} {...restProps}>
            <h3 key="card-title" className="card-title">
                {titleTemplate}
            </h3>
            {children}
        </div>
    );
};

export default CardHeader;