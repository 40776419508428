import React, { FC } from "react";

const ContentBody: FC = ({
   children,
}) => {
    return (
        <section className="content">
            <div className="container-fluid">
                {children}
            </div>
        </section>
    );
};

export default ContentBody;
