import React, { FC } from "react";
import AppButton from "../../../components/buttons/AppButton";
import { Link } from "react-router-dom";

const Header: FC = () => {

    return (
        <nav className="main-header navbar navbar-expand navbar-dark navbar-primary">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                {/*<li className="nav-item">
                    <button
                        onClick={() => toggleMenuSidebar()}
                        type="button"
                        className="nav-link"
                    >
                        <i className="fas fa-bars" />
                    </button>
                </li>*/}
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/tenants" className="nav-link">
                        {"Tenants"}
                    </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/clients" className="nav-link">
                        {"Clients"}
                    </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/sites" className="nav-link">
                        {"Sites"}
                    </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/probes" className="nav-link">
                        {"Probes"}
                    </Link>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <form name='logout' action='/logout' method='GET'>
                        <AppButton
                            icon={{ icon: "sign-out-alt", size: "lg" }}
                            type="submit"
                        />
                    </form>
                </li>
                {/* <li className="nav-item">
                    <button className="nav-link" type="button">
                        <i className="fas fa-search" />
                    </button>
                    <div className="navbar-search-block">
                        <form className="form-inline">
                            <div className="input-group input-group-sm">
                                <input
                                    className="form-control form-control-navbar"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-navbar"
                                        type="submit"
                                    >
                                        <i className="fas fa-search" />
                                    </button>
                                    <button
                                        className="btn btn-navbar"
                                        type="button"
                                        data-widget="navbar-search"
                                    >
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li> */}
                {/*<Messages />*/}
                {/*<Notifications />*/}
                {/*<Languages />*/}
                {/*<User />*/}
            </ul>
        </nav>
    );

};

export default Header;