import React, { FC, forwardRef, Ref, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Client, Paged, Tenant } from "../../services/Models";
import LinkButton from "../../components/buttons/LinkButton";
import CollapsibleCard from "../../components/templates/CollapsibleCard";
import { maxPageSize } from "../../const";
import SpinnerOverlay from "../../components/templates/SpinnerOverlay";
import { useGetClientsQuery, useLinkTenantClientMutation, useUnlinkTenantClientMutation } from "../../services/Clients";
import Callout from "../../components/templates/Callout";
import { ButtonGroup, Col, Dropdown, Row } from "react-bootstrap";
import AppButton from "../../components/buttons/AppButton";

interface Props {
    tenant: Tenant;
}

const TenantListingCard: FC<Props> = ({
    tenant,
}) => {

    const {
        isLoading: isClientsLoading,
        isFetching: isClientsFetching,
        isSuccess: isClientsSuccess,
        isError: isClientsError,
        data: clientsData,
        error: clientsError,
    } = useGetClientsQuery({ tenantId: tenant.tenantId, size: maxPageSize });

    const {
        isLoading: isAllClientsLoading,
        isFetching: isAllClientsFetching,
        isSuccess: isAllClientsSuccess,
        isError: isAllClientsError,
        data: allClientsData,
        error: allClientsError,
    } = useGetClientsQuery({ size: maxPageSize });

    const {
        data: availableClientsData,
    } = useMemo(() => ({ 
        data: _.differenceBy(allClientsData?.items || [], clientsData?.items || [], "id"),
    }), [ allClientsData, clientsData ]);

    const [
        linkTenantClient,
        {
            isError: isTenantClientLinkError,
            error: tenantClientLinkError,
        },
    ] = useLinkTenantClientMutation();

    const [
        unlinkTenantClient,
        {
            isError: isTenantClientUnlinkError,
            error: tenantClientUnlinkError,
        },
    ] = useUnlinkTenantClientMutation();

    const [ hasClients, setHasClients ] = useState((clientsData?.items?.length || 0) > 0);

    useEffect(() => {
        setHasClients((clientsData?.items?.length || 0) > 0);
    }, [ clientsData?.items?.length ]);

    type CustomToggleProps = {
        children?: React.ReactNode;
        onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
    };

    // eslint-disable-next-line react/display-name
    const CustomToggle = forwardRef((props: CustomToggleProps, ref: Ref<HTMLAnchorElement>) => (
        <LinkButton
            ref={ref}
            variant="outline-primary"
            size="xs"
            to=""
            onClick={e => {
                e.preventDefault();
                props.onClick(e);
            }}
        >
            &#x25bc;
        </LinkButton>
    ));


    const renderCardTools = (tenant: Tenant) => {
        return (
            <>
                <Dropdown
                    as={ButtonGroup}
                    className="mr-5"
                >
                    <LinkButton
                        key="add-client"
                        variant="outline-primary"
                        icon="plus"
                        size="xs"
                        to={`${tenant.tenantId}/clients/0/edit`}
                    >
                        Add Client
                    </LinkButton>

                    {
                        availableClientsData &&
                        <>
                            <Dropdown.Toggle
                                key="select-client"
                                as={CustomToggle}
                                split
                            />

                            <Dropdown.Menu>
                                {
                                    availableClientsData.map((client) => (
                                        <Dropdown.Item
                                            key={client.clientId}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                linkTenantClient({
                                                    tenantId: tenant.tenantId,
                                                    clientId: client.clientId,
                                                });
                                            }}
                                        >
                                            {client.name}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </>
                    }
                </Dropdown>

                <LinkButton
                    key="edit-tenant"
                    variant="tool"
                    icon="pencil-alt"
                    to={`${tenant.tenantId}/edit`}
                />
            </>
        );
    };

    const renderClient = (tenant: Tenant, client: Client) => {
        return (
            <Callout
                key={client.clientId}
                variant="primary"
            >
                <Row>
                    <Col sm="10">
                        <LinkButton
                            key="view-client"
                            variant="link"
                            to={`${tenant.tenantId}/clients/${client.clientId}`}
                        >
                            {client.name}
                        </LinkButton>
                    </Col>
                    <Col sm="2" className="text-right">
                        <AppButton
                            variant="outline-primary"
                            icon="times"
                            size="xs"
                            onClick={(e) => {
                                e.preventDefault();
                                unlinkTenantClient({
                                    tenantId: tenant.tenantId,
                                    clientId: client.clientId,
                                });
                            }}
                        >
                            Remove
                        </AppButton>
                    </Col>
                </Row>
            </Callout>
        );
    };

    const renderClients = (tenant: Tenant, clients: Paged<Client> | undefined) => {
        return (
            clients && clients.items &&
            <>
                {clients.items.map((client) => renderClient(tenant, client))}
            </>
        );
    };

    const renderTenantCard = (tenant: Tenant) => {
        return (
            <>
                <CollapsibleCard
                    title={tenant.name}
                    titleLinkTo={`${tenant.tenantId}`}
                    cardTools={renderCardTools(tenant)}
                    variant="primary"
                    collapsible={hasClients}
                >
                    <SpinnerOverlay
                        isLoading={isClientsLoading || isAllClientsLoading}
                        isFetching={isClientsFetching || isAllClientsFetching}
                        isSuccess={isClientsSuccess && isAllClientsSuccess}
                        isError={isClientsError || isAllClientsError || isTenantClientLinkError || isTenantClientUnlinkError}
                        error={clientsError || allClientsError || tenantClientLinkError || tenantClientUnlinkError}
                    >
                        {renderClients(tenant, clientsData)}
                    </SpinnerOverlay>
                </CollapsibleCard>
            </>
        );
    };

    return (
        <>
            {renderTenantCard(tenant)}
        </>
    );
};

export default TenantListingCard;