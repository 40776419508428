import React, { FC } from "react";

import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ContextCSRF from "../../components/contexts/ContextCSRF";

const Login: FC = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const error = params.get("error");
    const logout = params.get("logout");

    return (
        <>
            <section className="login-page">

                <div className="login-box">

                    <div className="login-logo">
                        <b>CPWP</b>
{" "}
Control
                    </div>

                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign-In</p>

                            <form action="/login" method="post">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Username" name="username" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon="user-astronaut" />
                                            </div>
                                        </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="Password" name="password" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon="lock" />
                                            </div>
                                        </div>
                                </div>
                                <Row>
                                    <Col sm="6">
                                        {/*<div className="icheck-primary">*/}
                                        {/*    <input type="checkbox" id="remember" />*/}
                                        {/*    <label htmlFor="remember">*/}
                                        {/*        Remember Me*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                    </Col>
                                    <Col sm="6">
                                        <ContextCSRF />
                                        <button type="submit" className="btn btn-primary btn-block">
                                            <FontAwesomeIcon className="mr-1" icon="sign-in-alt" fixedWidth />
                                            Sign-In
                                        </button>
                                    </Col>
                                </Row>
                                { error !== undefined && error !== null && (
                                    <Row className="mt-3">
                                        <Col sm="12">
                                            <div className="text-md-center text-danger">
                                                <strong>Invalid Credentials</strong>
                                                { error && <p>{error}</p> }
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                { logout !== undefined && logout !== null && (
                                    <Row className="mt-3">
                                        <Col sm="12">
                                            <div className="text-md-center text-info">
                                                <strong>Successfully Signed Out</strong>
                                                { logout && <p>{logout}</p> }
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </form>

                            {/*<p className="mb-1">*/}
                            {/*    <a href="forgot-password.html">I forgot my password</a>*/}
                            {/*</p>*/}
                            {/*<p className="mb-0">*/}
                            {/*    <a href="register.html" className="text-center">Register a new membership</a>*/}
                            {/*</p>*/}
                        </div>

                    </div>

                </div>

            </section>
        </>
    );
};

export default Login;