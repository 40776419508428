import React, { FC } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { ButtonType, ButtonVariant, isFontAwesomeIconProps, Size } from "../PropTypes";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
    className?: string;
    variant?: ButtonVariant;
    size?: Size;
    icon?: IconProp | FontAwesomeIconProps;
    disabled?: boolean;
    type?: ButtonType;
    onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

const AppButton: FC<Props> = ({
    children,
    className = "",
    variant = "primary",
    size = "md",
    icon,
    disabled,
    type = "button",
    onClick = () => { return; },
    ...restProps
}) => {
    let iconTemplate;

    if (icon) {
        const iconProps: FontAwesomeIconProps = isFontAwesomeIconProps(icon)
                                                ? icon
                                                : { icon: icon, size: "sm" };

        iconTemplate = <FontAwesomeIcon fixedWidth {...iconProps} />;
    }

    return (
        <Button
            {...restProps}
            variant={variant}
            disabled={disabled}
            className={`btn 
                       ${variant ? `btn-${variant}` : ""} 
                       ${size ? `btn-${size}` : ""} 
                       ${className}`}
            type={type}
            onClick={onClick}
        >
            {iconTemplate}
            {children}
        </Button>
    );
};

export default AppButton;
