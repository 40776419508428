import { cpwpApi } from "./CpwpApi";
import {
    isDateTimeRange,
    DateTimeRange,
    DateTimeRangesEnum,
    dateTimeRangesProvider,
} from "./Enums";
import moment from "moment";
import { Paged, TestRunState, TransformPagedResponseArg } from "./Models";

interface GetTestRunQueryArg {
    probeId: string;
    testRunStateId: string;
}

interface GetTestRunsQueryArg {
    probeId?: string;
    dateTimeRange?: DateTimeRangesEnum | DateTimeRange;
    page?: number;
    size?: number;
    compact?: boolean;
}

interface EmbeddedTestRunsPage {
    testRunStates: TestRunState[];
}

const testRunsApi =
    cpwpApi
        .enhanceEndpoints({
            addTagTypes: [ "TestRun" ],
        })
        .injectEndpoints({
            endpoints: (builder) => ({
                getTestRun: builder.query<TestRunState, GetTestRunQueryArg>({
                    query:
                        ({ probeId, testRunStateId }) =>
                            `/probes/${probeId}/test-runs/${testRunStateId}`,
                    providesTags:
                        (result, error, { probeId, testRunStateId }) =>
                            [ { type: "TestRun", probeId, testRunStateId } ],
                }),
                getTestRuns: builder.query<Paged<TestRunState>, GetTestRunsQueryArg>({
                    query:
                        ({ probeId = "0", dateTimeRange = "oneDay", page = 0, size = 20, compact = true  }) => {

                            const queryDateTimeRange: DateTimeRange =
                                isDateTimeRange(dateTimeRange)
                                ? dateTimeRange
                                : dateTimeRangesProvider[dateTimeRange].range();

                            return {
                                url: `/probes/${probeId}/test-runs`,
                                params: {
                                    scheduledRangeStart: moment(queryDateTimeRange.start).toISOString(),
                                    scheduledRangeEnd: moment(queryDateTimeRange.end).toISOString(),
                                    page: page,
                                    size: size,
                                    compact: compact,
                                },
                            };
                        },
                    transformResponse:
                        (result: TransformPagedResponseArg<EmbeddedTestRunsPage>): Paged<TestRunState> => {
                            return ({
                                items: result?._embedded?.testRunStates || [],
                                page: result?.page || { size: 20, totalElements: 0, totalPages: 0, number: 0 },
                            });
                        },
                    providesTags:
                        (result, error, { probeId, dateTimeRange, page, size, compact }) =>
                            [ { type: "TestRun", probeId, dateTimeRange, page, size, compact } ],
                }),
                getTestRunsLatest: builder.query<Paged<TestRunState>, GetTestRunsQueryArg>({
                    query:
                        ({ probeId = "0", compact = true  }) => {
                            return {
                                url: `/probes/${probeId}/test-runs/latest`,
                                params: {
                                    compact: compact,
                                },
                            };
                        },
                    transformResponse:
                        (result: TransformPagedResponseArg<EmbeddedTestRunsPage>): Paged<TestRunState> => {
                            return ({
                                items: result?._embedded?.testRunStates || [],
                                page: result?.page || { size: 20, totalElements: 0, totalPages: 0, number: 0 },
                            });
                        },
                    providesTags:
                        (result, error, { probeId, dateTimeRange, page, size, compact }) =>
                            [ { type: "TestRun", probeId, dateTimeRange, page, size, compact } ],
                }),
                refetchTestRuns: builder.mutation<null, void>({
                    queryFn:
                        () => ({ data: null }),
                    invalidatesTags:
                        () => [ { type: "TestRun" } ],
                }),
            }),
        });

export const {
    useGetTestRunQuery,
    useGetTestRunsQuery,
    useGetTestRunsLatestQuery,
    useRefetchTestRunsMutation,
} = testRunsApi;

