import { Moment, MomentInputObject } from "moment";

export interface PageMetadata {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface Paged<T> {
    items: T[];
    page: PageMetadata;
}

export interface Address {
    line1: string;
    line2: string;
    line3: string;
    line4: string;

    locality: string;
    region: string;
    country: string;

    code: string;
}

export interface GeoPoint {
    lat: number;
    lon: number;
}

export interface Location {
    name: string;
    address: Address;
    geoPoint: GeoPoint;
    geoPointDisplay?: {
        latD: number | string;
        latM: number | string;
        latS: number | string;
        lonD: number | string;
        lonM: number | string;
        lonS: number | string;
    };
}

export interface Tenant {
    id: string;
    tenantId: string;
    name: string;
}

export interface Client {
    id: string;
    clientId: string;
    name: string;
}

export interface Site {
    id: string;
    siteId: string;
    name: string;
}

export interface Probe {
    id: string;

    probeId: string;
    name: string;
    model: string;
    serialNumber: string;

    dns: string;
    vpnIpV4: string;
    vpnIpV6: string;

    location: Location;
}

export interface TestRunFeatureElementStepPassed extends TestRunFeatureElementStepBase {
    result: {
        duration: number;
        status: "passed";
    };
}

export interface TestRunFeatureElementStepFailed extends TestRunFeatureElementStepBase {
    result: {
        error_message: string;
        duration: number;
        status: "failed";
    };
}

export interface TestRunFeatureElementStepSkipped extends TestRunFeatureElementStepBase {
    result: {
        duration: number;
        status: "skipped";
    };
}

export interface TestRunFeatureElementStepOther extends TestRunFeatureElementStepBase {
    result: {
        duration: number;
        status: "undefined" | "pending" | string;
    };
}

export interface TestRunFeatureElementStepBase {
    result: unknown;
    line: number;
    keyword: "Given " | "When " | "Then " | "And " | "But " | string;
    name: string;
    match: {
        arguments: {
            val: string;
            offset: number;
        }[];
        location: string;
    }
}

export declare type TestRunFeatureElementStep = 
    | TestRunFeatureElementStepPassed
    | TestRunFeatureElementStepFailed
    | TestRunFeatureElementStepSkipped
    | TestRunFeatureElementStepOther;

export const isTestRunFeatureElementStepPassed = (step: TestRunFeatureElementStep): step is TestRunFeatureElementStepPassed => {
    return (step as TestRunFeatureElementStepPassed)?.result?.status === "passed";
};

export const isTestRunFeatureElementStepFailed = (step: TestRunFeatureElementStep): step is TestRunFeatureElementStepFailed => {
    return (step as TestRunFeatureElementStepFailed)?.result?.status === "failed";
};

export const isTestRunFeatureElementStepSkipped = (step: TestRunFeatureElementStep): step is TestRunFeatureElementStepSkipped => {
    return (step as TestRunFeatureElementStepSkipped)?.result?.status === "skipped";
};

export const isTestRunFeatureElementStepOther = (step: TestRunFeatureElementStep): step is TestRunFeatureElementStepOther => {
    return (step as TestRunFeatureElementStepOther)?.result?.status !== "passed"
        && (step as TestRunFeatureElementStepOther)?.result?.status !== "failed"
        && (step as TestRunFeatureElementStepOther)?.result?.status !== "skipped";
};

export interface TestRunFeatureBackground extends TestRunFeatureElementBase {
    type: "background";
    keyword: "Background";
}

export interface TestRunFeatureScenario extends TestRunFeatureElementBase {
    type: "scenario";
    keyword: "Scenario Outline";
}

export interface TestRunFeatureElementBase {
    id?: string;
    line: number;
    type: string;
    keyword: string;
    name: string;
    description: string;
    start_timestamp?: Moment | Date | string | number | (number | string)[] | MomentInputObject | null | undefined;
    steps: TestRunFeatureElementStep[];
}

export declare type TestRunFeatureElement = TestRunFeatureBackground | TestRunFeatureScenario;

export const isTestRunFeatureBackground = (element: TestRunFeatureElement): element is TestRunFeatureBackground => {
    return (element as TestRunFeatureBackground).type === "background";
};

export const isTestRunFeatureScenario = (element: TestRunFeatureElement): element is TestRunFeatureScenario => {
    return (element as TestRunFeatureScenario).type === "scenario";
};

export interface TestRunFeature {
    id: string;
    uri: string;
    line: number;
    type?: "feature" | string;
    keyword: "Feature" | string;
    name: string;
    description: string;
    tags: string[];
    elements: TestRunFeatureElement[];
}

export interface TestRunState {
    id: string;

    testRunId: string;

    probeId: string;

    scheduled: string;
    started: string;
    completed: string;

    count: number;
    ignoreCount: number;
    failureCount: number;
    assumptionFailureCount: number;
    failures: string[];
    runTime: number;
    successful: boolean;

    report: TestRunFeature[];
    reportHtml?: string;
}

export interface TransformPagedResponseArg<T> {
    _embedded: T;
    page: PageMetadata;
}
