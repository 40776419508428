import { cpwpApi } from "./CpwpApi";
import { Paged, Tenant, TransformPagedResponseArg } from "./Models";

interface GetTenantQueryArg {
    tenantId: string;
}

interface SaveTenantQueryArg {
    tenantId: string;
    tenant: Partial<Tenant>;
}

interface GetTenantsQueryArg {
    page?: number;
    size?: number;
    compact?: boolean;
}

interface EmbeddedTenantsPage {
    tenants: Tenant[];
}

const tenantsApi =
    cpwpApi
        .enhanceEndpoints({
            addTagTypes: [ "Tenant" ],
        })
        .injectEndpoints({
            endpoints: (builder) => ({
                saveTenant: builder.mutation<Tenant, SaveTenantQueryArg>({
                    query:
                        ({ tenantId, tenant }) => ({
                            url: `/tenants/${tenantId}`,
                            method: "PUT",
                            data: tenant,
                        }),
                    invalidatesTags:
                        () => [ { type: "Tenant" } ],
                }),
                getTenant: builder.query<Tenant, GetTenantQueryArg>({
                    query:
                        ({ tenantId }) =>
                            `/tenants/${tenantId}`,
                    providesTags:
                        (result, error, { tenantId }) =>
                            [ { type: "Tenant", tenantId } ],
                }),
                getTenants: builder.query<Paged<Tenant>, GetTenantsQueryArg>({
                    query:
                        ({ page = 0, size = 20, compact = true  }) => ({
                            url: `/tenants`,
                            params: {
                                page: page,
                                size: size,
                                compact: compact,
                            },
                        }),
                    transformResponse:
                        (result: TransformPagedResponseArg<EmbeddedTenantsPage>): Paged<Tenant> => ({
                            items: result?._embedded?.tenants || [],
                            page: result?.page || { size: 20, totalElements: 0, totalPages: 0, number: 0 },
                        }),
                    providesTags:
                        (result, error, { page, size, compact }) =>
                            [ { type: "Tenant", page, size, compact } ],
                }),
            }),
        });

export const {
    useGetTenantQuery,
    useSaveTenantMutation,
    useGetTenantsQuery,
} = tenantsApi;

