import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import "react-toastify/dist/ReactToastify.min.css";

import "./index.scss";
import jquery from "jquery";
import Inputmask from "inputmask";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBarcode,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronCircleRight,
    faCubes,
    faExclamationCircle,
    faFingerprint,
    faGlobeAfrica,
    faLaptop,
    faLock,
    faNetworkWired,
    faPencilAlt,
    faPlus,
    faQuestionCircle,
    faSatellite,
    faSignInAlt,
    faSignOutAlt,
    faSync,
    faTimes,
    faTimesCircle,
    faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faBarcode,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronCircleRight,
    faCubes,
    faExclamationCircle,
    faFingerprint,
    faGlobeAfrica,
    faLaptop,
    faLock,
    faNetworkWired,
    faPencilAlt,
    faPlus,
    faQuestionCircle,
    faSatellite,
    faSignInAlt,
    faSignOutAlt,
    faSync,
    faTimes,
    faTimesCircle,
    faUserAstronaut,
);

import {
    Chart as ChartJS,

    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,

    BarElement,
    PointElement,
    LineElement,

    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import "chartjs-adapter-moment";
ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,

    BarElement,
    PointElement,
    LineElement,

    Title,
    Tooltip,
    Legend,
    Filler,
);

import Main from "./templates/main/Main";
import Login from "./scenes/login/Login";

Object.assign(global, {
    $: jquery,
    jQuery: jquery,
});

const App: React.FC = () => {
    useEffect(() => {
        applyInputMasks();
    });

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<Main />} />
        </Routes>
    );
};

function applyInputMasks() {
    $(".inputmask, [data-inputmask], [data-inputmask-mask], [data-inputmask-alias], [data-inputmask-regex]").each(
        function (ndx, lmnt) {
            if (lmnt.inputmask === undefined) {
                Inputmask().mask(lmnt);
            }
        },
    );
}

export default App;
