import React, { FC } from "react";

interface Props {
    className?: string;
}

const CardTools: FC<Props> = ({
    children,
    className = "",
    ...restProps
}) => {
    return (
        <div {...restProps} className={`card-tools ${className}`}>
            {children}
        </div>
    );
};

export default CardTools;