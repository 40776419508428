import React, { FC, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { AxiosQueryError, isAxiosQueryError } from "../../services/CpwpApi";

interface Props {
    isLoading?: boolean;
    isFetching?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: AxiosQueryError | unknown;
}

const SpinnerOverlay: FC<Props> = ({ 
    children,
    isLoading = false,
    isFetching = false,
    isSuccess = false,
    isError = false,
    error = undefined,
}) => {

    useEffect(() => {
        if (isError && !isSuccess) {
            if (error && isAxiosQueryError(error)) {
                toast.error(error.statusText || "An unexpected error occurred...");
            } else {
                console.error("error", error);
                toast.error("An unexpected error occurred...");
            }
        }
    }, [ isError, error, isSuccess ]);

    return (
        <div className="overlay-wrapper">
            { (isLoading || isFetching) && (
                <div className="overlay">
                    <Spinner animation="border" />
                </div>
            ) }
            {children}
        </div>
    );
};

export default SpinnerOverlay;
