import React, { FC } from "react";

import { useParams } from "react-router-dom";

import ContextSideBarTenant from "./ContextSideBarTenant";
import ContextSideBarClient from "./ContextSideBarClient";
import ContextSideBarSite from "./ContextSideBarSite";
import ContextSideBarProbe from "./ContextSideBarProbe";
import ContextSideBarTestRun from "./ContextSideBarTestRun";

const ContextSideBar: FC = () => {
    const { tenantId, clientId, siteId, probeId, testRunStateId } = useParams();

    const testRunBasePath =
        (testRunStateId ? "../" : "");

    const probeBasePath =
        (probeId ? "../" : "") +
        testRunBasePath;

    const siteBasePath =
        (siteId ? "../" : "") +
        probeBasePath;

    const clientBasePath =
        (clientId ? "../" : "") +
        siteBasePath;

    const tenantBasePath =
        (tenantId ? "../" : "") +
        clientBasePath;

    return (
        <>
            { probeId && testRunStateId &&
              <ContextSideBarTestRun probeId={probeId} testRunStateId={testRunStateId} basePath={testRunBasePath} />
            }
            { probeId && <ContextSideBarProbe probeId={probeId} basePath={probeBasePath} /> }
            { siteId && <ContextSideBarSite siteId={siteId} basePath={siteBasePath} /> }
            { clientId && <ContextSideBarClient clientId={clientId} basePath={clientBasePath} /> }
            { tenantId && <ContextSideBarTenant tenantId={tenantId} basePath={tenantBasePath} /> }
        </>
    );
};

export default ContextSideBar;