import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export declare type Variant =
    "primary" | "secondary" |
    "success" | "danger" | "warning" | "info" |
    "dark" | "light" | string;

export declare type ButtonVariant =
    Variant | "link" | "outline-primary" | "outline-secondary" |
    "outline-success" | "outline-danger" | "outline-warning" | "outline-info" |
    "outline-dark" | "outline-light" | string;

export declare type ButtonType = "button" | "reset" | "submit";

export declare type Color =
    "primary" | "secondary" |
    "success" | "danger" | "warning" | "info" |
    "dark" | "light" | "white" | "muted" | string;

export declare type Size = "xs" | "sm" | "md" | "lg" | string;

export declare type Icon = IconProp | FontAwesomeIconProps;

export const isFontAwesomeIconProps = (icon: Icon): icon is FontAwesomeIconProps => {
    return (icon as FontAwesomeIconProps).icon !== undefined;
};

export declare type setFormValueCallback = (field: string, value: unknown, shouldValidate?: boolean) => void;
