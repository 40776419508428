import React, { FC } from "react";
import _ from "lodash";
import { xsrfCookieName, xsrfParameterName } from "../../const";

const ContextCSRF: FC = () => {
    const getCookie = (name: string) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = _.trim(cookies[i]);
                if (cookie.substring(0, name.length + 1) === (name + "=")) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    const csrfToken = getCookie(xsrfCookieName);

    return (
        <input type="hidden" name={xsrfParameterName} value={csrfToken ? csrfToken : ""} />
    );
};

export default ContextCSRF;