import React, { FC } from "react";
import ContextDashboard from "../../templates/dashboard/ContextDashboard";
import ContentHeader from "../../components/templates/ContentHeader";
import { Col, Row } from "react-bootstrap";
import ContentBody from "../../components/templates/ContentBody";
import { useParams } from "react-router-dom";
import TestRunReport from "./TestRunReport";

const TestRunView: FC = () => {

    const { probeId, testRunStateId } = useParams();

    return (
        <>
            <ContextDashboard>
                <ContentHeader>
                    <Row>
                        <Col sm="6">
                            <h2 className="float-left mr-5">Test Run Report</h2>
                        </Col>
                        <Col sm="6">
                        </Col>
                    </Row>
                </ContentHeader>
                <ContentBody>
                    { probeId && testRunStateId &&
                      <TestRunReport probeId={probeId} testRunStateId={testRunStateId} /> }
                </ContentBody>
            </ContextDashboard>
        </>
    );
};

export default TestRunView;