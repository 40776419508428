import React, { FC } from "react";

import { Col, Row } from "react-bootstrap";

import ContentBody from "../../components/templates/ContentBody";

import ContextSideBar from "../../components/contexts/ContextSideBar";
import { useParams } from "react-router-dom";

const ContextDashboard: FC = ({
    children,
}) => {
    const { tenantId, clientId, siteId, probeId, testRunStateId } = useParams();

    const hasContext = (): boolean => {
        return (tenantId || clientId || siteId || probeId || testRunStateId) as unknown as boolean;
    };

    return (
        <>
            <ContentBody>
                <Row className="mb-2">
                    {
                        hasContext() &&
                        <Col sm="3">
                            <ContextSideBar/>
                        </Col>
                    }
                    <Col sm={hasContext() ? "9" : "12"}>
                        {children}
                    </Col>
                </Row>
            </ContentBody>
        </>
    );
};

export default ContextDashboard;