import React, { FC } from "react";
import { useGetClientQuery } from "../../services/Clients";

import { Col, Row } from "react-bootstrap";

import ContentHeader from "../templates/ContentHeader";
import ContentBody from "../templates/ContentBody";
import SpinnerOverlay from "../templates/SpinnerOverlay";

import LinkButton from "../buttons/LinkButton";

interface Props {
    clientId: string;
    basePath?: string;
}

const ContextSideBarClient: FC<Props> = ({
    clientId,
    basePath = "",
}) => {

    const { isLoading, isFetching, isSuccess, isError,
            data: client, error } = useGetClientQuery({ clientId });

    return (
        <>
            <SpinnerOverlay
                isLoading={isLoading}
                isFetching={isFetching}
                isSuccess={isSuccess}
                isError={isError}
                error={error}
            >
                { client ? (
                    <>
                        <ContentHeader>
                            <Row>
                                <Col sm="5">
                                    <h3 className="float-left mr-5">Client</h3>
                                </Col>
                                <Col sm="1">
                                    <LinkButton
                                        key="edit-tenant"
                                        variant="tool"
                                        icon="pencil-alt"
                                        to={`${basePath}../${client.clientId}/edit`}
                                    />
                                </Col>
                                <Col sm="3">
                                    <LinkButton
                                        variant="outline-primary"
                                        icon="chevron-up"
                                        to={`${basePath}..`}
                                        size="xs"
                                        className="btn-block"
                                    >
                                        Clients
                                    </LinkButton>
                                </Col>
                            </Row>
                        </ContentHeader>
                        <ContentBody>
                            <Row className="mb-2">
                                <Col sm="12">
                                    <dl className="dl-sm mb-1">
                                        <dd>{client?.clientId}</dd>
                                        <dt>Name</dt>
                                        <dd>{client?.name}</dd>
                                    </dl>
                                </Col>
                            </Row>
                        </ContentBody>
                    </>
                ) : null }
            </SpinnerOverlay>
        </>
    );

};

export default ContextSideBarClient;