import moment, { DurationInputArg1, DurationInputArg2, Moment, MomentInput } from "moment";

const ddToDMS = (decimalDegrees: number): number[] => {
    const sign = decimalDegrees < 0 ? -1 : 1;
    const dd = Math.abs(decimalDegrees);

    const degrees = Math.trunc(dd);
    const minutes = Math.trunc(60 * (dd - degrees));
    const seconds = Math.trunc((3600 * (dd - degrees)) - (60 * minutes));

    const signedDegrees = sign * degrees;

    return [ signedDegrees, minutes, seconds ];
};

const parseNumber = (num: number | string | undefined): number => {
    if (!num) return 0;

    if (typeof num === "number") return num;

    try {
        return parseInt(num);
    } catch (e) {
        return 0;
    }
};

const dmsToDD = (degrees: number | string | undefined, minutes: number | string | undefined, seconds: number | string | undefined): number => {
    const d: number = parseNumber(degrees);
    const m: number = parseNumber(minutes);
    const s: number = parseNumber(seconds);

    const sign = d < 0 ? -1 : 1;

    return sign * (Math.abs(d)  + (m / 60) + (s / 3600));
};

const snap = (
    dateInp: MomentInput,
    durationInp: { inp?: DurationInputArg1, unit?: DurationInputArg2 },
    method: "ceil" | "floor" | "round" | "abs"
): Moment => {
    const date = moment(dateInp);
    const duration = moment.duration(durationInp.inp, durationInp.unit);

    return moment(Math[method]((+date) / (+duration)) * (+duration));
};

const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] =>
    (Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]);

export {
    ddToDMS,
    dmsToDD,
    snap,
    enumKeys,
};