import React, { FC } from "react";
import { Col, FormLabel, Row } from "react-bootstrap";

interface Props {
    label?: string;
    fieldColSize?: number;
}

const LabelFieldGroup: FC<Props> = ({
    children, 
    label = "", 
    fieldColSize = 10,
}) => {
    return (
        <Row className="form-group">
            <FormLabel column="sm">{label}</FormLabel>
            <Col sm={fieldColSize}>
                {children}
            </Col>
        </Row>
    );
};

export default LabelFieldGroup;